import React, { useState } from "react";
import "./home.css";
import { Row, Image, Col, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";
import logoMap from "../../assets/photos/map.png";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      {isMobile ? (
        <Container fluid className="secondMobileContainer">
          <div className="mobileStretch">
            <div className="secondMobileContentOne">
              <h2>About São Tomé and Príncipe (STP)</h2>
              {/* <p>
                STP is a small island nation located in the Gulf of Guinea, off
                the western coast of Central Africa, and is known for its
                natural beauty and rich culture.
              </p> */}
              <div>
                <Button
                  size="lg"
                  className="readMoreMobileButton float-start"
                  href="/about-stp"
                >
                  Read More
                </Button>
              </div>
            </div>
            <div className="secondMobileContentTwo">
              <Image src={logoMap} className="mapMobilelogo" />
            </div>
            <p>
              STP is a small island nation located in the Gulf of Guinea, off
              the western coast of Central Africa, and is known for its natural
              beauty and rich culture.
            </p>
          </div>

          <Row className="mobileSecondRowAbout">
            <Col xs={12} sm={12}>
              <h2 className="h2">
                Corporation Purpose and Benefits of Investing
              </h2>
              <p>
                The purpose of the STP Development Investment Corporation is to
                invest in the development of STP with a core focus on three key
                sectors: banking, power, and infrastructure. The corporation
                aims to create a Tax-Free Economic Development Zone in STP that
                will attract businesses and investors from around the world. The
                corporation is committed to promoting sustainable development
                and social progress in STP, while also generating attractive
                returns for its investors.
              </p>
              <div>
                <Button
                  size="lg"
                  className="learnMobileMorebutton float-start"
                  href="/about-the-corporation"
                >
                  Learn More
                </Button>
              </div>
            </Col>
            <hr className="mobileHr"></hr>
            <Col xs={12} sm={12}>
              <h2>Citizenship</h2>
              <p>
                One of the key benefits of becoming an investor in the STP
                Development Investment Corporation is the eligibility to apply
                for a new STP passport. STP passports are highly valued for
                their fast-track EU visa and residency/work permit access, which
                can open a range of new opportunities for business, travel, and
                personal growth.
              </p>
            </Col>
            <hr className="mobileHr"></hr>
            <Col xs={12} sm={12}>
              <h2>Banking & Visa Access</h2>
              <p>
                Investors in the STP Development Investment Corporation will
                have full banking abilities in STP, including the ability to
                open accounts, make transfers, and access a range of financial
                products and services. In addition, the corporation will offer
                qualified investors a Haute Black Visa card with no limit, which
                can be used to make purchases and withdraw cash at millions of
                locations worldwide.
              </p>
            </Col>
            <hr className="mobileHr"></hr>
            <Col xs={12} sm={12}>
              <h2>Investment</h2>
              <p>
                Investment into the corporation will include investments into
                infrastructure, banking, and sectors that establish commercial
                activities in the soon- to-be-launched Special Economic Zone.w
                opportunities for business, travel, and personal growth.
              </p>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="secondContainer1">
          <div className="stretch">
            <div className="secondContentOne">
              <h2>About São Tomé and Príncipe (STP)</h2>
              <p>
                STP is a small island nation located in the Gulf of Guinea, off
                the western coast of Central Africa, and is known for its
                natural beauty and rich culture.
              </p>
              <div>
                {/* <Button
                  size="lg"
                  className="readMorebutton float-start"
                  href="/about-stp"
                >
                  Read More
                </Button> */}
                <Link
                  className="link readMorebutton float-start"
                  to={"/about-stp"}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="secondContentTwo">
              <Image src={logoMap} className="maplogo" />
            </div>
          </div>

          <div className="stretchTwo">
            <Col md={5} className="columnOne mb-4">
              <h3>Corporation Purpose and Benefits of Investing</h3>
              <p>
                The purpose of the STP Development Investment Corporation is to
                invest in the development of STP with a core focus on three key
                sectors: banking, power, and infrastructure. The corporation
                aims to create a Tax-Free Economic Development Zone in STP that
                will attract businesses and investors from around the world. The
                corporation is committed to promoting sustainable development
                and social progress in STP, while also generating attractive
                returns for its investors.
              </p>
              <div>
                {/* <Button
                  size="lg"
                  className="learnMorebutton float-start"
                  href="/about-the-corporation"
                >
                  Learn More
                </Button> */}
                <Link
                  className="link learnMorebutton float-start"
                  to={"/about-the-corporation"}
                >
                  Learn More
                </Link>
              </div>
            </Col>
            <Col md={5} className="columnTwo mb-4">
              <hr></hr>
              <h4>Citizenship</h4>
              <p>
                One of the key benefits of becoming an investor in the STP
                Development Investment Corporation is the eligibility to apply
                for a new STP passport. STP passports are highly valued for
                their fast-track EU visa and residency/work permit access, which
                can open a range of new opportunities for business, travel, and
                personal growth.
              </p>
              <hr className="mt-4"></hr>
              <h4 className="mt-4">Banking & Visa Access</h4>
              <p>
                Investors in the STP Development Investment Corporation will
                have full banking abilities in STP, including the ability to
                open accounts, make transfers, and access a range of financial
                products and services. In addition, the corporation will offer
                qualified investors a Haute Black Visa card with no limit, which
                can be used to make purchases and withdraw cash at millions of
                locations worldwide.
              </p>
              <hr className="mt-4"></hr>
              <h4 className="mt-4">Investment</h4>
              <p>
                Investment into the corporation will include investments into
                infrastructure, banking, and sectors that establish commercial
                activities in the soon- to-be-launched Special Economic Zone.
              </p>
            </Col>
            <Col md={2}></Col>
          </div>
        </Container>
      )}
    </>
  );
}

export default About;
