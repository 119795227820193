import React, { useEffect } from "react";
import aeroplane from "../../assets/videos/aeroplane.mp4";
import "./about.css";
import { isMobile } from "react-device-detect";
import { Row, Col, Container, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutCorpOne from "../../assets/photos/aboutCorpOne.png";
import AboutCorpTwo from "../../assets/photos/aboutCorpTwo.png";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";
function AboutCorporation() {
  useEffect(() => {
    document.title = "About The Corporation - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="aboutMobileCorpContainer">
          <Row className="AboutCorpMobileRow">
            <Col md={12} className="ColOne">
              <h4>Our Mission</h4>
              <h1>We Aim to Build a Better World</h1>
            </Col>
            <Col md={12} className="ColOne">
              <p>
                The purpose of the STP Development Investment Corporation is to
                invest in the development of STP with a core focus on three key
                sectors: banking, power, and infrastructure.
              </p>
            </Col>
            <Col md={12} className="ColOne">
              <p>
                The corporation aims to create a Tax-Free Economic Development
                Zone in STP that will attract businesses and investors from
                around the world.
              </p>
            </Col>
            <Col md={12} className="ColOne">
              <p>
                The corporation is committed to promoting sustainable
                development and social progress in STP, while also generating
                attractive returns for its investors.
              </p>
            </Col>
            <Col md={12} className="AboutImageCol">
              <Image src={AboutCorpOne} className="aboutMobileTwoCorp"></Image>
            </Col>
            <Col
              md={12}
              className="ColOne"
              style={{ backgroundColor: "#f1f1f1", padding: 20 }}
            >
              <h4>Corporation Management</h4>
              <h1>Investment Decisions For the Future</h1>
            </Col>

            <Col md={12} className="ColOne">
              <p>
                The corporation is managed by a team of professionals with a
                proven track record in investment management, financial
                analysis, and risk assessment.
              </p>
            </Col>

            <Col md={12} className="ColOne">
              <p>
                The team is responsible for making all investment decisions and
                ensuring that the corporation’s assets are being used in the
                most effective and efficient manner possible.
              </p>
            </Col>
            <Col md={12} className="ColOne">
              <p>
                The team also works closely with local partners and stakeholders
                to identify and capitalize on new opportunities in STP’s rapidly
                developing economy.
              </p>
            </Col>
            <Col md={12} className="ColOne">
              <h4>Corporations Exit Plan</h4>
              <h1>Flexibility and Liquidity for Investors</h1>
            </Col>

            <Col md={12} className="ColOne">
              <p>
                The corporation's exit plan is designed to provide flexibility
                and liquidity for investors who wish to sell their shares back
                to the corporation or transfer them to a new investor.
              </p>
            </Col>
            <Col md={12} className="ColOne">
              <p>
                The corporation's management team will work closely with
                investors to ensure a smooth and seamless exit process that
                maximizes returns while minimizing risks and costs.
              </p>
            </Col>

            <Col md={12} className="AboutImageCol">
              <video
                playsInline
                autoPlay
                muted
                loop
                className="aeroplaneMobileVideo"
              >
                <source src={aeroplane} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="aboutCorpContainer">
          <Row className="AboutCorpRow">
            <Col md={5} className="ColOne">
              <h4>Our Mission</h4>
              <h1>We Aim to Build a Better World</h1>
              <p>
                The purpose of the STP Development Investment Corporation is to
                invest in the development of STP with a core focus on three key
                sectors: banking, power, and infrastructure.
              </p>
              <p>
                The corporation aims to create a Tax-Free Economic Development
                Zone in STP that will attract businesses and investors from
                around the world.
              </p>
              <p>
                The corporation is committed to promoting sustainable
                development and social progress in STP, while also generating
                attractive returns for its investors.
              </p>
            </Col>
            <Col md={7}>
              <Image src={AboutCorpOne} className="aboutTwoCorp"></Image>
            </Col>
          </Row>

          <Row className="AboutCorpTwoRow">
            <Col md={5} className="ColCorpOne">
              <Image src={AboutCorpTwo} className="aboutCorpTwo"></Image>
            </Col>
            <Col md={7} className="RowColCorpSecond">
              <h4>Corporation Management</h4>
              <h1>Investment Decisions For the Future</h1>
              <p>
                The corporation is managed by a team of professionals with a
                proven track record in investment management, financial
                analysis, and risk assessment.
              </p>
              <p>
                The team is responsible for making all investment decisions and
                ensuring that the corporation’s assets are being used in the
                most effective and efficient manner possible.
              </p>
              <p>
                The team also works closely with local partners and stakeholders
                to identify and capitalize on new opportunities in STP’s rapidly
                developing economy.
              </p>
            </Col>
          </Row>

          <Row className="AboutCorpThreeRow">
            <Col md={5} className="ColOne">
              <h4>Corporations Exit Plan</h4>
              <h1>Flexibility and Liquidity for Investors</h1>
              <p>
                The corporation's exit plan is designed to provide flexibility
                and liquidity for investors who wish to sell their shares back
                to the corporation or transfer them to a new investor.
              </p>
              <p>
                The corporation's management team will work closely with
                investors to ensure a smooth and seamless exit process that
                maximizes returns while minimizing risks and costs.
              </p>
            </Col>
            <Col md={7}>
              <video playsInline autoPlay muted loop className="aeroplaneVideo">
                <source src={aeroplane} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default AboutCorporation;
