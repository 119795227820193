import React, { useState } from "react";
import "./invest.css";
import {
  Row,
  Image,
  Col,
  Container,
  Button,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/photos/stpLogo.webp";
import { AiFillWarning, AiFillDelete } from "react-icons/ai";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { countries } from "../common/countries";
import SignaturePad from "react-signature-canvas";
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import { FileUploader } from "react-drag-drop-files";
import { db } from "../../firebase_setup/firebase";
import { collection, addDoc } from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import isEmail from "validator/lib/isEmail";

const fileTypes = ["JPEG", "PNG", "GIF", "PDF"];
// import styles from "./styles.module.css";
function MainForm() {
  const navigate = useNavigate();
  const storage = getStorage();
  const [validated, setValidated] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfDeclaration, setDateOfDeclaration] = useState(null);
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [corrCountry, setCorrCountry] = useState("");
  const [zip, setZip] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [referalError, setReferalError] = useState(false);
  const [firstDeclarationName, setDeclarationFirstName] = useState("");
  const [lastDeclarationName, setDeclarationLastName] = useState("");
  const [gender, setGender] = useState("");
  const [marritalStatus, setMarritalStatus] = useState("");
  const [nationalityStatus, setNationalityStatus] = useState("");
  const [addressProofCorr, setAddressProofcorr] = useState({
    proofs: [],
  });

  const [addressProofPermanent, setAddressProofPermanent] = useState({
    proofs: [],
  });
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentAddress1, setPermanentAddress1] = useState("");
  const [permanentAddress2, setPermanentAddress2] = useState("");
  const [permanentState, setPermanentState] = useState("");
  const [permanentCountry, setPermanentCountry] = useState("");
  const [permanentZip, setPermanentZip] = useState("");
  const [otherAddressProof, setOtherAddressProof] = useState("");
  const [otherAddressProofPermanent, setOtherAddressProofPermanent] =
    useState("");
  const [otherinputFeild, setOtherInpuFeild] = useState(false);
  const [otherinputFeildPermanent, setOtherInpuFeildPermanent] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [signError, setSignError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [showSignatureForm, setShowSignatureForm] = useState(false);
  const [dateofSignedError, setDateSignedError] = useState(false);
  const [trimmedDataURL, setTrimmedUrl] = useState("");
  const [successSign, setSuccessSignature] = useState(false);
  const [addressFrontRead, setAddressFrontRead] = useState([]);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [postalError, setPostalError] = useState(false);
  const [addressCountryError, setAddressCountryError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [declarationFirstNameError, setDeclarationFirstError] = useState(false);
  const [declarationLastNameError, setDeclarationLastError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [addressProofDocError, setAddressProofDocError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please enter all required fields"
  );
  const [nationalityStatusError, setNationalityStatusError] = useState(false);
  const [maritalError, setMaritalError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const filter =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleClose = () => {
    setErrorPopup(false);
  };
  const handleSuccessClose = () => {
    setSuccessPopup(false);
    navigate("/sign-document");
  };

  const blurError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setDateSignedError(true);
    } else {
      setDateSignedError(false);
    }
  };

  const blurStreetError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setStreetError(true);
    } else {
      setStreetError(false);
    }
  };

  const blurCityError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setCityError(true);
    } else {
      setCityError(false);
    }
  };

  const blurStateError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setStateError(true);
    } else {
      setStateError(false);
    }
  };

  const blurPostalError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setPostalError(true);
    } else {
      setPostalError(false);
    }
  };

  const blurAddressCountryError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setAddressCountryError(true);
    } else {
      setAddressCountryError(false);
    }
  };

  const blurPhoneError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const blurDeclarationFirstError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setDeclarationFirstError(true);
    } else {
      setDeclarationFirstError(false);
    }
  };

  const blurDeclarationLastError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setDeclarationLastError(true);
    } else {
      setDeclarationLastError(false);
    }
  };

  const blurRefferalError = (event) => {
    console.log("e", filter.test(event));
    if (
      event == null ||
      event == "" ||
      event == undefined ||
      !filter.test(event)
    ) {
      setReferalError(true);
    } else {
      setReferalError(false);
    }
  };

  const blurCountryError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setCountryError(true);
    } else {
      setCountryError(false);
    }
  };

  const blurFirstNameError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
  };
  const blurLastNameError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
  };

  const blurDobError = (event) => {
    if (event == null || event == "" || event == undefined) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  };

  const blurEmailError = (event) => {
    if (
      event == null ||
      event == "" ||
      event == undefined ||
      !filter.test(event)
    ) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const removeFile = (file) => {
    console.log("file", file);
    const desertRef = ref(storage, `images/investDocuments/${file.name}`);

    deleteObject(desertRef)
      .then(() => {
        console.log("uploaded files", uploadedFiles, {
          name: file.name,
          url: file.url,
        });
        uploadedFiles.map((uploadedFile) => {
          if (uploadedFile.name == file.name) {
            console.log("hellooo");
            let index = uploadedFiles.indexOf(uploadedFile);
            console.log("shdvchsvdchj", index);
            uploadedFiles.splice(index, 1);
            console.log("sdvcjhdvhjdv uploadedFiles", uploadedFiles);
            setUploadedFiles([].concat(uploadedFiles));
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setFirstNameError(false);
    setLastNameError(false);
    setReferalError(false);
    setCountryError(false);
    setStreetError(false);
    setCityError(false);
    setStateError(false);
    setPostalError(false);
    setAddressCountryError(false);
    setPhoneError(false);
    setEmailError(false);
    setDeclarationFirstError(false);
    setDeclarationLastError(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (
      !referalCode.trim() ||
      !firstName.trim() ||
      !lastName.trim() ||
      !country.trim() ||
      !address1.trim() ||
      !city.trim() ||
      !state.trim() ||
      !zip.trim() ||
      !corrCountry.trim() ||
      !phoneNumber.trim() ||
      !email.trim() ||
      //   addressFrontRead.length === 0 ||
      !firstDeclarationName.trim() ||
      !lastDeclarationName.trim()
    ) {
      setErrorPopup(true);
      setErrorMessage("Please enter all required fields");
      setLoading(false);
    } else if (!marritalStatus.trim()) {
      setMaritalError(true);
      setErrorPopup(true);
      setErrorMessage("Please select Marital status");
      setLoading(false);
    } else if (!gender.trim()) {
      setErrorPopup(true);
      setErrorMessage("Please select Gender");
      setLoading(false);
      setGenderError(true);
    } else if (!nationalityStatus.trim()) {
      setErrorPopup(true);
      setNationalityStatusError(true);
      setErrorMessage("Please select the Nationality status");
      setLoading(false);
    } else if (!filter.test(email)) {
      setEmailError(true);
      setErrorPopup(true);
      setErrorMessage("Please enter valid email");
      setLoading(false);
    } else if (uploadedFiles.length === 0) {
      setErrorMessage("Please upload valid files");
      setUploadError(true);
      setErrorPopup(true);
      setLoading(false);
    } else if (addressProofCorr.proofs.length === 0) {
      setErrorMessage("Please check valid address proof document options");
      setAddressProofDocError(true);
      setErrorPopup(true);
      setLoading(false);
    } else if (!filter.test(referalCode)) {
      setErrorMessage("Please enter valid email");
      //   setReferalError(true);
      setErrorPopup(true);
      setLoading(false);
    } else if (otherinputFeild && otherAddressProof.length === 0) {
      setErrorPopup(true);
      setLoading(false);
    } else if (dateOfBirth == null) {
      setErrorMessage("Please select valid Date Of Birth");
      setErrorPopup(true);
      setDobError(true);
      setLoading(false);
    } else if (dateOfDeclaration == null) {
      setErrorMessage("Please select valid Date of Sign");
      setErrorPopup(true);
      setDateSignedError(true);
      setLoading(false);
    } else if (!trimmedDataURL.trim()) {
      setErrorMessage("Please sign before submitting");
      setErrorPopup(true);
      setSignError(true);
      setLoading(false);
    } else {
      let identifyDetails = {
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        maritalStatus: marritalStatus,
        dateOfBirth: dateOfBirth,
        nationality: country,
        nationalityStatus: nationalityStatus,
      };

      let addressCorrespondanceDetails = {
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zipCode: zip,
        country: corrCountry,
        AddressProofDocuments: addressProofCorr.proofs,
        otherAddressProof: otherAddressProof,
        phoneNumber: phoneNumber,
        email: email,
      };

      let uploadDetails = {
        documents: uploadedFiles,
      };

      let addressPermanentDetails = {
        address1: permanentAddress1,
        address2: permanentAddress2,
        city: permanentCity,
        state: permanentState,
        zipCode: permanentZip,
        country: permanentCountry,
        AddressProofDocuments: addressProofPermanent.proofs,
        otherAddressProofPermanent: otherAddressProofPermanent,
      };

      let declarationDetails = {
        firstName: firstDeclarationName,
        lastName: lastDeclarationName,
        signature: trimmedDataURL,
        dateofSigned: dateOfDeclaration,
      };

      let investFormData = {
        referalCode: referalCode,
        identifyDetails: identifyDetails,
        addressCorrespondanceDetails: addressCorrespondanceDetails,
        addressPermanentDetails: addressPermanentDetails,
        declarationDetails: declarationDetails,
        uploadDetails: uploadDetails,
        createdAt: new Date(),
        status: 0,
        phoneNumber: phoneNumber,
        email: email,
      };

      try {
        const docRef = await addDoc(
          collection(db, "investForm"),
          investFormData
        );
        setLoading(false);
        setSuccessPopup(true);
      } catch (err) {
        setLoading(false);
        console.log("err", err);
      }
    }
    // setShowSignatureForm(true)
  };

  React.useMemo(() => {
    if (addressProofCorr.proofs.includes("other")) {
      setOtherInpuFeild(true);
    } else {
      setOtherInpuFeild(false);
    }

    if (addressProofPermanent.proofs.includes("other")) {
      setOtherInpuFeildPermanent(true);
    } else {
      setOtherInpuFeildPermanent(false);
    }
  }, [addressProofCorr, addressProofPermanent, uploadedFiles]);

  const handleAddressProofCorr = (event) => {
    const { value, checked } = event.target;
    // console.log(`${value} is ${checked}`);
    const { proofs } = addressProofCorr;
    if (checked) {
      setAddressProofDocError(false);
      setAddressProofcorr({
        proofs: [...proofs, value],
      });
    } else {
      setAddressProofcorr({
        proofs: proofs.filter((e) => e !== value),
      });
    }
  };

  const handleAddressProofPermanent = (event) => {
    const { value, checked } = event.target;
    const { proofs } = addressProofPermanent;
    if (checked) {
      setAddressProofPermanent({
        proofs: [...proofs, value],
      });
    } else {
      setAddressProofPermanent({
        proofs: proofs.filter((e) => e !== value),
      });
    }
  };

  const handleMaritalStatus = (value) => {
    setMarritalStatus(value);
    setMaritalError(false);
  };

  const fileAddressFrontUpload = (files) => {
    setUploadLoading(true);
    setUploadError(false);
    for (let i = 0; i < files.length; i++) {
      console.log("dfvdfvhjdfvhj");

      if (files[i].name) {
        var dateTime = Date.now();
        const storageRef = ref(
          storage,
          "images/investDocuments/" +
            Math.floor(dateTime / 1000) +
            "-" +
            files[i].name
        );

        // uploadedFiles.push(Math.floor(dateTime / 1000) + "-" + files[i].name);

        const uploadTask = uploadBytesResumable(storageRef, files[i]);
        //   console.log("uploadTaskuploadTask", uploadTask);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            console.log("error", error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setAddressFrontRead((current) => [...current, downloadURL]);
              setUploadedFiles((current) => [
                ...current,
                {
                  name: Math.floor(dateTime / 1000) + "-" + files[i].name,
                  url: downloadURL,
                },
              ]);
              setUploadLoading(false);
            });
          }
        );
      }
    }
    console.log("files", uploadedFiles);
  };

  console.log("addressFrontReadaddressFrontRead", addressFrontRead);

  let sigPad = useState({});
  const clear = () => {
    sigPad.clear();
  };

  const handleNationalityStatus = (value) => {
    setNationalityStatusError(false);
    setNationalityStatus(value);
  };

  const handleGender = (value) => {
    setGender(value);
    setGenderError(false);
  };

  const trim = () => {
    setSignError(false);
    setTrimmedUrl(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    let base64 = sigPad.getTrimmedCanvas().toDataURL("image/png");
    if (base64?.length > 0) {
      setSuccessSignature(true);

      setTimeout(() => {
        setSuccessSignature(false);
      }, 500);
    }
  };
  return (
    <Container fluid className="FormContainer">
      {/* <Image src={logo} className="float-center mt-4" width="200"></Image> */}
      <Form
        noValidate
        validated={validated}
        onSubmit={(event) => handleSubmit(event)}
      >
        <Row className="mt-4 formRow">
          <Col md={12} className="headingCol">
            <p>A. Referral Code</p>
          </Col>
          <Col md={12} className="bodyCol">
            <Form.Group className="mb-3" controlId="referral">
              <Form.Label>
                Referral Code <span>*</span>
              </Form.Label>
              <Form.Control
                className="referalInput"
                required
                value={referalCode}
                onFocus={() => setReferalError(false)}
                onChange={(e) => setReferalCode(e.target.value)}
                onBlur={(event) => blurRefferalError(event.target.value)}
                type="email"
              />
              {referalError && (
                <>
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter valid
                    Email
                  </p>
                </>
              )}
              <Form.Control.Feedback type="invalid">
                <AiFillWarning className="warning" />
                Please enter valid email
              </Form.Control.Feedback>
              <p className={isMobile ? "paraTag" : "mt-2"}>
                Please provide the referral code provided by an STP Development
                Investment Corp. Certified representative office
              </p>
            </Form.Group>
          </Col>
          <Col md={12} className="headingCol">
            <p>B. Identity Details</p>
          </Col>
          <Col md={12} className="bodyCol">
            <Row>
              <Form.Label className="formLabel">
                1. Name of the Applicant <span>*</span>
              </Form.Label>
              <Form.Group as={Col} controlId="firstname">
                <Form.Control
                  required
                  value={firstName}
                  onBlur={(event) => blurFirstNameError(event.target.value)}
                  onFocus={() => setFirstNameError(false)}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {firstNameError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter first
                    name
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
                <p className={isMobile ? "paraTag" : "mt-2"}>First Name</p>
              </Form.Group>

              <Form.Group as={Col} controlId="lastname">
                <Form.Control
                  required
                  value={lastName}
                  onBlur={(event) => blurLastNameError(event.target.value)}
                  onFocus={() => setLastNameError(false)}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {lastNameError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter last name
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
                <p className={isMobile ? "paraTag" : "mt-2"}>Last Name</p>
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Col md={6} className="checkBoxStyle">
                <Form.Label className="formLabel">
                  2a. Gender <span>*</span>
                </Form.Label>
                <Form.Check
                  required
                  label="Male"
                  value="male"
                  name="group1"
                  type="radio"
                  id="radio-gender-1"
                  onChange={(e) => handleGender(e.target.value)}
                />
                <Form.Check
                  required
                  label="Female"
                  value="female"
                  name="group1"
                  type="radio"
                  id="radio-gender-2"
                  onChange={(e) => handleGender(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
                {genderError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please select Gender
                  </p>
                )}
              </Col>

              <Col md={6} className="checkBoxStyle">
                <Form.Label className="formLabel">
                  2b. Marital Status <span>*</span>
                </Form.Label>
                <Form.Check
                  required
                  label="Single"
                  value="single"
                  name="group2"
                  type="radio"
                  id="radio-status-1"
                  onChange={(e) => handleMaritalStatus(e.target.value)}
                />
                <Form.Check
                  required
                  label="Married"
                  value="married"
                  name="group2"
                  type="radio"
                  id="radio-status-2"
                  onChange={(e) => handleMaritalStatus(e.target.value)}
                />
                {maritalError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please select Marital
                    Status
                  </p>
                )}
              </Col>
            </Row>
            <Form.Group className="mt-3">
              <Form.Label className="formLabel">
                2c. Date of Birth
                <span>*</span>
              </Form.Label>

              <DatePicker
                className="datePick react-datepicker-wrapper"
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
                value={dateOfBirth}
                maxDate={new Date()}
                yearAriaLabel="Year"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                format="MM/dd/y"
              />
              {dobError && (
                <>
                  <p
                    style={{
                      color: "#dc3545",
                      textAlign: "initial",
                      fontSize: 15,
                      marginTop: 5,
                    }}
                  >
                    <AiFillWarning className="warning" /> Please Select a Valid
                    Date
                  </p>
                </>
              )}
              <p className="mt-2">Date</p>
            </Form.Group>

            <Form.Group controlId="formGroupPassword">
              <Form.Label className="formLabel">
                3. Nationality<span className="spanTag">*</span>
              </Form.Label>

              <Form.Select
                placeholder="Choose"
                required
                onChange={(e) => setCountry(e.target.value)}
                onBlur={(event) => blurCountryError(event.target.value)}
                onFocus={() => setCountryError(false)}
                className="countrySelect"
              >
                {countries.length > 0 &&
                  countries.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
              </Form.Select>
              {countryError && (
                <p className="errorMessageStyle">
                  <AiFillWarning className="warning" /> Please select valid
                  country
                </p>
              )}
              <Form.Control.Feedback type="invalid">
                <AiFillWarning className="warning" />
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mt-3">
              <Col md={6} className="checkBoxStyle">
                <Form.Label className="formLabel">
                  4. Status <span>*</span>
                </Form.Label>
                <Form.Check
                  required
                  label="Resident Individual"
                  value="individual"
                  name="group3"
                  type="radio"
                  id="radio-grad-1"
                  onChange={(e) => handleNationalityStatus(e.target.value)}
                />
                <Form.Check
                  required
                  label="Non Resident"
                  value="non-resident"
                  name="group3"
                  type="radio"
                  id="radio-grad-2"
                  onChange={(e) => handleNationalityStatus(e.target.value)}
                />
                <Form.Check
                  required
                  label="Foreign National"
                  value="foreign-national"
                  name="group3"
                  type="radio"
                  id="radio-grad-3"
                  onChange={(e) => handleNationalityStatus(e.target.value)}
                />
                {nationalityStatusError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please select the
                    Nationality status
                  </p>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={12} className="headingCol">
            <p>C. Address Details</p>
          </Col>
          <Col md={12} className="bodyCol">
            <Form.Label>
              1. Address for Correspondence <span>*</span>
            </Form.Label>
            <Form.Group className="mb-3 mt-4" controlId="formGridAddress1">
              <Form.Control
                required
                value={address1}
                onBlur={(event) => blurStreetError(event.target.value)}
                onFocus={() => setStreetError(false)}
                onChange={(e) => setAddress1(e.target.value)}
              />
              {streetError && (
                <p className="errorMessageStyle">
                  <AiFillWarning className="warning" /> Please enter street
                  address
                </p>
              )}
              <Form.Control.Feedback type="invalid">
                <AiFillWarning className="warning" />
                This field is required.
              </Form.Control.Feedback>
              <p className="mt-2">
                Street Address <span className="text-danger">*</span>
              </p>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Control
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />

              <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                Street Address Line 2 (Optional)
              </p>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Control
                  required
                  value={city}
                  onBlur={(event) => blurCityError(event.target.value)}
                  onFocus={() => setCityError(false)}
                  onChange={(e) => setCity(e.target.value)}
                />
                {cityError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter city
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>

                <p className="mt-2">
                  City <span className="text-danger">*</span>
                </p>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Control
                  required
                  value={state}
                  onBlur={(event) => blurStateError(event.target.value)}
                  onFocus={() => setStateError(false)}
                  onChange={(e) => setState(e.target.value)}
                />
                {stateError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter state
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>

                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                  State / Province <span className="text-danger">*</span>
                </p>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Control
                  required
                  value={zip}
                  onBlur={(event) => blurPostalError(event.target.value)}
                  onFocus={() => setPostalError(false)}
                  type="text"
                  onChange={(e) => setZip(e.target.value)}
                />
                {postalError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter valid
                    Zip/Postal Code
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>

                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                  Postal / Zip Code <span className="text-danger">*</span>
                </p>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Select
                  placeholder="Choose"
                  required
                  onBlur={(event) =>
                    blurAddressCountryError(event.target.value)
                  }
                  onFocus={() => setAddressCountryError(false)}
                  onChange={(e) => setCorrCountry(e.target.value)}
                >
                  {countries.length > 0 &&
                    countries.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                </Form.Select>
                {addressCountryError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please select valid
                    country
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>

                <p className="mt-2">
                  Country <span className="text-danger">*</span>
                </p>
              </Form.Group>
            </Row>

            <Form.Label className="mt-3">
              2. Phone Number <span>*</span>
            </Form.Label>
            <Form.Control
              required
              value={phoneNumber}
              type="text"
              onBlur={(event) => blurPhoneError(event.target.value)}
              onFocus={() => setPhoneError(false)}
              className="referalInput"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {phoneError && (
              <p className="errorMessageStyle">
                <AiFillWarning className="warning" /> Please enter a valid phone
                number.
              </p>
            )}
            <Form.Control.Feedback type="invalid">
              <AiFillWarning className="warning" />
              This field is required.
            </Form.Control.Feedback>

            <Form.Label className="mt-3">
              3. Email <span>*</span>
            </Form.Label>
            <Form.Control
              required
              value={email}
              className="referalInput"
              onBlur={(event) => blurEmailError(event.target.value)}
              onFocus={() => setEmailError(false)}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <>
                <p
                  style={{
                    color: "#dc3545",
                    textAlign: "initial",
                    fontSize: 15,
                    marginTop: 5,
                  }}
                >
                  <AiFillWarning className="warning" /> Please enter valid email
                </p>
              </>
            )}
            <Form.Control.Feedback type="invalid">
              <AiFillWarning className="warning" />
              Please enter valid email
            </Form.Control.Feedback>
            <p className="mt-2">example@example.com</p>
            <Form.Label className="mt-3">
              4. Proof of Address to be Provided by Applicant <span>*</span>
            </Form.Label>
            <Row className="mt-2">
              <Col md={6} className="checkBoxStyle">
                <Form.Check
                  required
                  label="Passport"
                  value="passport"
                  name="group4"
                  type="checkbox"
                  id="checkbox-1"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Driving License"
                  value="license"
                  name="group4"
                  type="checkbox"
                  id="checkbox-2"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Registered Lease/Sale Agreement of Residence"
                  value="leaseagreement"
                  name="group4"
                  type="checkbox"
                  id="checkbox-3"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Latest Telephone Bill"
                  value="telephoneBill"
                  name="group4"
                  type="checkbox"
                  id="checkbox-4"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Latest Gas Bill"
                  value="gasBill"
                  name="group4"
                  type="checkbox"
                  id="checkbox-5"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Other"
                  value="other"
                  name="group4"
                  type="checkbox"
                  id="checkbox-6"
                  onChange={handleAddressProofCorr}
                />
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>

                {otherinputFeild && (
                  <Form.Control
                    required
                    value={otherAddressProof}
                    placeholder="Please type another option here"
                    onChange={(e) => setOtherAddressProof(e.target.value)}
                  />
                )}
              </Col>
              <Col md={6} className="checkBoxStyle">
                <Form.Check
                  required
                  label="Ration Card"
                  value="rationcard"
                  name="group4"
                  type="checkbox"
                  id="checkbox-7"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Voter Identity Card"
                  value="votercard"
                  name="group4"
                  type="checkbox"
                  id="checkbox-8"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Latest Bank Account Statement/Passbook"
                  value="statement"
                  name="group4"
                  type="checkbox"
                  id="checkbox-9"
                  onChange={handleAddressProofCorr}
                />
                <Form.Check
                  required
                  label="Latest Electricity Bill"
                  value="electricityBill"
                  name="group4"
                  type="checkbox"
                  id="checkbox-10"
                  onChange={handleAddressProofCorr}
                />
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
              </Col>
              {addressProofDocError && (
                <p className="errorMessageStyle">
                  <AiFillWarning className="warning" /> Please check the
                  relevent options
                </p>
              )}
            </Row>

            <Form.Label className="mt-4">
              5. Permanent Address of Resident Applicant If Different From C1
              Above
            </Form.Label>
            <Form.Group className="mb-3 mt-4" controlId="formGridAddress1">
              <Form.Control
                value={permanentAddress1}
                onChange={(e) => setPermanentAddress1(e.target.value)}
              />
              <p className="mt-2">Street Address</p>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Control
                value={permanentAddress2}
                onChange={(e) => setPermanentAddress2(e.target.value)}
              />
              <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                Street Address Line 2
              </p>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Control
                  value={permanentCity}
                  onChange={(e) => setPermanentCity(e.target.value)}
                />

                <p className="mt-2">City</p>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Control
                  value={permanentState}
                  onChange={(e) => setPermanentState(e.target.value)}
                />

                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                  State / Province
                </p>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Control
                  value={permanentZip}
                  type="text"
                  onChange={(e) => setPermanentZip(e.target.value)}
                />

                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>
                  Postal / Zip Code
                </p>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Select
                  placeholder="Choose"
                  onChange={(e) => setPermanentCountry(e.target.value)}
                >
                  {countries.length > 0 &&
                    countries.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                </Form.Select>

                <p className="mt-2">Country</p>
              </Form.Group>
            </Row>

            <Form.Label className="mt-4">
              6. Proof of Address to be Provided by Applicant
            </Form.Label>
            <Row className="mt-2">
              <Col md={6} className="checkBoxStyle">
                <Form.Check
                  label="Passport"
                  value="passport"
                  name="group5"
                  type="checkbox"
                  id="checkbox-11"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Driving License"
                  value="license"
                  name="group5"
                  type="checkbox"
                  id="checkbox-12"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Registered Lease/Sale Agreement of Residence"
                  value="leaseagreement"
                  name="group5"
                  type="checkbox"
                  id="checkbox-13"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Latest Telephone Bill"
                  value="telephoneBill"
                  name="group5"
                  type="checkbox"
                  id="checkbox-14"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Latest Gas Bill"
                  value="gasBill"
                  name="group5"
                  type="checkbox"
                  id="checkbox-15"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Other"
                  value="other"
                  name="group5"
                  type="checkbox"
                  id="checkbox-16"
                  onChange={handleAddressProofPermanent}
                />

                {otherinputFeildPermanent && (
                  <Form.Control
                    required
                    value={otherAddressProofPermanent}
                    placeholder="Please type another option here"
                    onChange={(e) =>
                      setOtherAddressProofPermanent(e.target.value)
                    }
                  />
                )}
              </Col>
              <Col md={6} className="checkBoxStyle">
                <Form.Check
                  label="Ration Card"
                  value="rationcard"
                  name="group5"
                  type="checkbox"
                  id="checkbox-17"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Voter Identity Card"
                  value="votercard"
                  name="group5"
                  type="checkbox"
                  id="checkbox-18"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Latest Bank Account Statement/Passbook"
                  value="statement"
                  name="group5"
                  type="checkbox"
                  id="checkbox-19"
                  onChange={handleAddressProofPermanent}
                />
                <Form.Check
                  label="Latest Electricity Bill"
                  value="electricityBill"
                  name="group5"
                  type="checkbox"
                  id="checkbox-20"
                  onChange={handleAddressProofPermanent}
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} className="headingCol">
            <p>D. File Upload</p>
          </Col>
          <Col md={12} className="bodyCol">
            <Form.Label>
              <span>*</span>
            </Form.Label>
            {/* <Form.Control
              type="file"
              controlId="formFileMultiple"
              required
              multiple
              onChange={(e) => fileAddressFrontUpload(e.target.files)}
            />
            <Form.Control.Feedback type="invalid">
              <AiFillWarning className="warning" />
              This field is required.
            </Form.Control.Feedback> */}

            <FileUploader
              multiple={true}
              handleChange={fileAddressFrontUpload}
              name="file"
              maxSize={8}
              required={true}
              types={fileTypes}
            />
            {uploadError && (
              <p className="errorMessageStyle">
                <AiFillWarning className="warning" /> Please upload valid files.
              </p>
            )}

            {uploadLoading && (
              <div className="InputWidth mt-4 text-center">
                <Spinner animation="border" variant="dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {uploadedFiles &&
              uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file) => {
                return (
                  <div className="uploadStyle" style={{ display: "flex" }}>
                    <p className="fileName">{file.name}</p>
                    <AiFillDelete
                      onClick={() => removeFile(file)}
                      className="deleteButton"
                    />
                  </div>
                );
              })}
          </Col>

          <Col md={12} className="headingCol">
            <p>E. Declaration</p>
          </Col>
          <Col md={12} className="bodyCol">
            <h6>
              I hereby declare that the information provided in this form is
              accurate and complete. I confirm that any information is found
              incorrect and/or incomplete that leads a violation of regulations
              may initiate legal actions, and I accept that I am the responsible
              party for any and all charges, penalties and violations.
            </h6>
            <Row>
              <Form.Label className="formLabel mt-4">
                Name of the Applicant <span>*</span>
              </Form.Label>
              <Form.Group as={Col} controlId="firstname">
                <Form.Control
                  value={firstDeclarationName}
                  onBlur={(event) =>
                    blurDeclarationFirstError(event.target.value)
                  }
                  onFocus={() => setDeclarationFirstError(false)}
                  required
                  onChange={(e) => setDeclarationFirstName(e.target.value)}
                />
                {declarationFirstNameError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter first
                    name.
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>First Name</p>
              </Form.Group>

              <Form.Group as={Col} controlId="lastname">
                <Form.Control
                  value={lastDeclarationName}
                  onBlur={(event) =>
                    blurDeclarationLastError(event.target.value)
                  }
                  onFocus={() => setDeclarationLastError(false)}
                  required
                  onChange={(e) => setDeclarationLastName(e.target.value)}
                />
                {declarationLastNameError && (
                  <p className="errorMessageStyle">
                    <AiFillWarning className="warning" /> Please enter last
                    name.
                  </p>
                )}
                <Form.Control.Feedback type="invalid">
                  <AiFillWarning className="warning" />
                  This field is required.
                </Form.Control.Feedback>
                <p className={isMobile ? "paraTag mt-2" : "mt-2"}>Last Name</p>
              </Form.Group>
            </Row>
            <Row>
              <Form.Label className="formLabel mt-4">
                Signature <span>*</span>
              </Form.Label>
              <div className="sigContainer float-center">
                <SignaturePad
                  canvasProps={{ className: "sigPad" }}
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                />
              </div>
              <div className="buttonsBlock">
                <div className="parentButtons">
                  <Tooltip
                    placement="left"
                    visible={successSign}
                    overlay={<span>Signature saved !</span>}
                  >
                    <Button
                      className={isMobile ? "mobileButton1" : "button1"}
                      onClick={() => trim()}
                    >
                      Save
                    </Button>
                  </Tooltip>
                  <Button
                    className={isMobile ? "mobileButton2" : "button2"}
                    onClick={() => clear()}
                  >
                    Clear
                  </Button>
                </div>
              </div>
              {/* {trimmedDataURL && (
                <div style={{ float: "left", display: "flex" }}>
                  <Form.Label className="formLabel mt-4">
                    Your signature
                  </Form.Label>
                  <Image
                    src={trimmedDataURL}
                    width={100}
                    className="signatureImage"
                  ></Image>
                </div>
              )} */}

              {signError && (
                <>
                  <p
                    style={{
                      color: "#dc3545",
                      textAlign: "initial",
                      fontSize: 15,
                      marginTop: 5,
                    }}
                  >
                    <AiFillWarning className="warning" /> Please Sign and save
                    the signature
                  </p>
                </>
              )}
            </Row>
            <Row>
              <Form.Group className="mt-3">
                <Form.Label className="formLabel">
                  Date Signed
                  <span>*</span>
                </Form.Label>

                <DatePicker
                  className="datePick react-datepicker-wrapper"
                  onChange={(date) => {
                    setDateOfDeclaration(date);
                  }}
                  value={dateOfDeclaration}
                  maxDate={new Date()}
                  yearAriaLabel="Year"
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  format="MM/dd/y"
                />
                {/* <DatePicker
                  selected={dateOfDeclaration}
                  className="datePick"
                  required
                  isClearable={true}
                  onFocus={() => setDateSignedError(false)}
                  maxDate={new Date()}
                  onBlur={(event) => blurError(event.target.value)}
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => setDateOfDeclaration(date)}
                /> */}
                {dateofSignedError && (
                  <>
                    <p
                      style={{
                        color: "#dc3545",
                        textAlign: "initial",
                        fontSize: 15,
                        marginTop: 5,
                      }}
                    >
                      <AiFillWarning className="warning" /> Please Select a
                      Valid Date
                    </p>
                  </>
                )}
                <p className="mt-2">Date</p>
              </Form.Group>
            </Row>
          </Col>

          <Col md={12} className="bodyCol ">
            {/* <Button size="lg" type="submit" className="submitBtn">
              Submit
            </Button> */}
            <Button
              size="lg"
              type="submit"
              className="submitBtn"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        show={errorPopup}
        onHide={() => handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="center ModalTitle errorTitle">
            Error
            <Image
              src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='25' height='25' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"
              style={{ paddingLeft: 5, paddingBottom: 2 }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center">{errorMessage}</h6>
          <Row
            style={{ flexDirection: "row", justifyContent: "space-between" }}
            className="mt-4"
          >
            <Col>
              <center>
                <Button className="okButton" onClick={() => handleClose()}>
                  Ok
                </Button>
              </center>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={successPopup}
        onHide={() => handleSuccessClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="center ModalTitle successTitle">
            Step 1 Completed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center">
            Thank you for the information. Now please sign review and submit the
            document.
          </h6>
          <Row
            style={{ flexDirection: "row", justifyContent: "space-between" }}
            className="mt-4"
          >
            <Col>
              <center>
                <Button
                  className="okButton"
                  onClick={() => handleSuccessClose()}
                >
                  Review & Sign Document
                </Button>
              </center>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* <iframe
        title="Subscription Agreement"
        src="https://www.jotform.com/sign/231980790032051/invite/01h5kpenzte752cbf02b7788d6?signEmbed=1"
       
        width={"100%"}
        height={800}
      ></iframe> */}
    </Container>
  );
}

export default MainForm;
