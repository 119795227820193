import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
// import { getFirestore } from "firebase/firestore/lite";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAtG83qQNAjeM1qF1dTySRf_inDY5XBLx0",
  authDomain: "stpdevlp.firebaseapp.com",
  projectId: "stpdevlp",
  storageBucket: "stpdevlp.appspot.com",
  messagingSenderId: "891870791460",
  appId: "1:891870791460:web:3746bbcd0fc29da8cf55b1",
  //   measurementId: "G-MTHM35EQLW",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const db = getFirestore(app);
