import React, { useEffect, useState } from "react";
import "./invest.css";
import { Row, Image, Col, Container, Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";

function SignDocument() {
  const [loading, setLoading] = useState(true);
  const [textLoading, settextLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        settextLoading(false);
      }, 5000);
    }
  }, [loading]);
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="signDocumentContainer">
          <Row className="signDocumentRow">
            {loading ? (
              <div className="InputWidth mt-4 text-center">
                <Spinner animation="border" variant="dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ height: "calc(100vh - 130px)" }}>
                {textLoading && (
                  <p className="mobileinvestNote">
                    We kindly request that you carefully review all details of
                    the document before signing. To ensure utmost accuracy and
                    compliance,{" "}
                    <b
                      style={{
                        color: "red",
                        fontWeight: 800,
                        fontFamily: "Poppins",
                      }}
                    >
                      you may be asked to sign your name, signature, and other
                      necessary fields more than once
                    </b>{" "}
                    when signing important documents.
                  </p>
                )}
                <iframe
                  title="Subscription Agreement"
                  src="https://www.jotform.com/sign/231980790032051/invite/01h5kpenzte752cbf02b7788d6?signEmbed=1"
                  width={"100%"}
                  height={"100%"}
                ></iframe>
              </div>
            )}
          </Row>
        </Container>
      ) : (
        <Container fluid className="signDocumentContainer">
          <Row className="signDocumentRow">
            {loading ? (
              <div className="InputWidth mx-4 text-center">
                <Spinner animation="border" variant="dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {textLoading && (
                  <p className="investNote">
                    We kindly request that you carefully review all details of
                    the document before signing. To ensure utmost accuracy and
                    compliance,{" "}
                    <b
                      style={{
                        color: "red",
                        fontWeight: 800,
                        fontFamily: "Poppins",
                      }}
                    >
                      you may be asked to sign your name, signature, and other
                      necessary fields more than once
                    </b>{" "}
                    when signing important documents.
                  </p>
                )}
                <iframe
                  title="Subscription Agreement"
                  src="https://www.jotform.com/sign/231980790032051/invite/01h5kpenzte752cbf02b7788d6?signEmbed=1"
                  width={"100%"}
                  height={800}
                ></iframe>
              </>
            )}
          </Row>
        </Container>
      )}
    </>
  );
}

export default SignDocument;
