import React, { useEffect } from "react";
import home from "../../assets/videos/home.mp4";
import "./home.css";
import { Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";
import About from "./about";
import Invest from "./invest";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";
import { Link } from "react-router-dom";
function Home() {
  useEffect(() => {
    document.title = "Home - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      <Container
        fluid
        className={isMobile ? "mainMobileContainer" : "mainContainer"}
      >
        {isMobile ? (
          <>
            <div className="mobile">
              <video playsInline id="background-video" autoPlay muted loop>
                <source src={home} type="video/mp4" />
              </video>
              <div className="overlay">
                <div className="top-50 homeHeadings">
                  <h1 className="mainHeading">
                    SÃO TOMÉ AND PRÍNCIPE DEVELOPMENT INVESTMENT CORP.
                  </h1>
                  <h4 className="secondHeading">
                    Investing into a brighter future
                  </h4>
                  <div className="homeButtons">
                    <Button
                      className="investBtn"
                      size="lg"
                      href="/how-to-apply"
                    >
                      How To Invest
                    </Button>
                    <Button className="contactButton" size="lg" href="/contact">
                      Contact Us
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <About />
            <Invest />
          </>
        ) : (
          <>
            <Container fluid className="firstContainer">
              <div className="ParallaxVideo">
                <video playsInline id="background-video" autoPlay muted loop>
                  <source src={home} type="video/mp4" />
                </video>
                <div className="homecontent">
                  <h1 className="mainHeading">SÃO TOMÉ AND PRÍNCIPE</h1>
                  <h1 className="mainHeading">DEVELOPMENT INVESTMENT CORP.</h1>
                  <h4 className="secondHeading">
                    Investing into a brighter future
                  </h4>
                  <div className="homeButtons">
                    {/* <Button
                      size="lg"
                      className="investBtn"
                      href="/how-to-apply"
                    > */}
                    <Link className="link investBtn" to={"/how-to-apply"}>
                      How To Invest
                    </Link>
                    {/* </Button> */}
                    {/* <Button className="contactBtn" size="lg" href="/contact">
                      Contact Us
                    </Button> */}
                    <Link
                      className="link  btn btn-primary contactBtn"
                      to={"/contact"}
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
            <About />
            <Invest />
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default Home;
