import React, { useEffect } from "react";
import "./invest.css";
import { Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MainForm from "./form";
import { isMobile } from "react-device-detect";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";
function InvestForm() {
  useEffect(() => {
    document.title = "Invest Now - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="investFormMobileContainer">
          <h2>Invest Today</h2>
          <p>
            The initial step in the application process is to complete the
            subscription agreement form below. Upon filling out the agreement,
            it should be submitted along with the subscription amount plus
            associated fees. The subscription amount and fees are dependent on
            the specific investment option chosen by the applicant.{" "}
          </p>

          <Row>
            <MainForm />
          </Row>
        </Container>
      ) : (
        <Container fluid className="investFormContainer">
          <h2>Invest Today</h2>
          <p>
            The initial step in the application process is to complete the
            subscription agreement form below. Upon filling out the agreement,
            it should be submitted along with the subscription amount plus
            associated fees. The subscription amount and fees are dependent on
            the specific investment option chosen by the applicant.{" "}
          </p>

          <Row>
            <MainForm />
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default InvestForm;
