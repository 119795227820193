import React, { useEffect } from "react";

import "./more.css";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";

function Apply() {
  useEffect(() => {
    document.title = "How To Apply - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="applyMobileContainer">
          <h4>How to Apply</h4>
          <h1>Application Process</h1>
          <h4 className="applyMobileText">
            To apply now click <a href="/invest-now">here</a> OR contact one of
            our Global Representative offices by <a href="/contact">email</a> or{" "}
            <a href="/contact">phone</a>.
          </h4>

          <Row>
            <Col md={12} className="colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">
                  1. Sign Documents, Pay Subscription Amount and Associated Fees
                </h5>
                <p className="cardText">
                  The initial step in the application process is to complete the
                  subscription agreement form, which can be accessed through the
                  provided link <a href="/invest-now">here</a>. Upon filling out
                  the agreement, it should be submitted along with the necessary
                  supporting documents, subscription amount plus associated
                  fees. The subscription amount and fees are dependent on the
                  specific investment option chosen by the applicant.
                </p>
              </div>
            </Col>

            <Col md={12} className="mt-3 colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">2. Passport Application</h5>
                <p className="cardText">
                  Upon receiving the subscription agreement/amount, documents,
                  and associated fees, the São Tomé and Príncipe government or a
                  representative office will review the application. If all
                  requirements are met, the applicant will be provided with the
                  necessary paperwork to proceed with a passport application.
                </p>
              </div>
            </Col>

            <Col md={12} className="mt-3 colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">
                  3. Processing and Additional Documentation
                </h5>
                <p className="cardText">
                  Once the passport application is complete and conditional
                  approval has been granted, the government may request
                  additional documents to give final approval. These documents
                  may include financial records, birth certificates, medical
                  certificates, and business or employment-related documents.
                  Providing these additional documents is crucial for the
                  government to assess the applicant's eligibility.
                </p>
              </div>
            </Col>

            <Col md={12} className="mt-3 colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">4. Visit a Representative Office</h5>
                <p className="cardText">
                  As part of the passport application process, applicants are
                  required to visit a representative office of São Tomé and
                  Príncipe to provide biometric information, such as
                  fingerprints and photographs. This step helps in the
                  identification and verification of the applicant's identity
                  and is an important part of the due diligence process. Local
                  offices can be found <a href="/our-offices">here</a>.
                </p>
              </div>
            </Col>

            <Col md={12} className="mt-3 colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">5. Passport Issuance</h5>
                <p className="cardText">
                  After visiting a representative office, finalizing the
                  necessary paperwork, and providing all required biometric
                  data, the applicant will be issued a passport by the São Tomé
                  and Príncipe government. The passport issuance typically takes
                  around 7-10 business days after the final approval is granted.
                  The passport serves as official proof of citizenship and
                  enables the holder to travel internationally.
                </p>
              </div>
            </Col>

            <Col md={12} className="mt-3 colMobile12">
              <div className="applyMobileCol">
                <h5 className="cardTitle">
                  6. Banking/Visa Issuance & Investing
                </h5>
                <p className="cardText">
                  As new citizens of São Tomé and Príncipe and investors in the
                  STP Development Investment Corp., applicants will gain full
                  banking privileges, including the ability to open a bank
                  account that facilitates global account transfers. Access to a
                  range of financial products and services, including the Haute
                  Black VISA card, will be provided through our partner bank,
                  STP Development Bank.
                </p>
                <Button className="applyMobileButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="applyContainer">
          <h4>How to Apply</h4>
          <h1>Application Process</h1>
          <h4 className="applyText">
            To apply now click <a href="/invest-now">here</a> OR contact one of
            our Global Representative offices by <a href="/contact">email</a> or{" "}
            <a href="/contact">phone</a>.
          </h4>
          <Row>
            <Col md={3}>
              <div className="p-4 applyCol">
                <h5 className="cardTitle">
                  1. Sign Documents, Pay Subscription Amount and Associated Fees
                </h5>
                <p className="cardText">
                  The initial step in the application process is to complete the
                  subscription agreement form, which can be accessed through the
                  provided link <a href="/invest-now">here</a>. Upon filling out
                  the agreement, it should be submitted along with the necessary
                  supporting documents, subscription amount plus associated
                  fees. The subscription amount and fees are dependent on the
                  specific investment option chosen by the applicant.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={3}>
              <div className="p-4 applyCol">
                <h5 className="cardTitle">2. Passport Application</h5>
                <p className="cardText">
                  Upon receiving the subscription agreement/amount, documents,
                  and associated fees, the São Tomé and Príncipe government or a
                  representative office will review the application. If all
                  requirements are met, the applicant will be provided with the
                  necessary paperwork to proceed with a passport application.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={3}>
              <div className="p-4 applyCol">
                <h5 className="cardTitle">
                  3. Processing and Additional Documentation
                </h5>
                <p className="cardText">
                  Once the passport application is complete and conditional
                  approval has been granted, the government may request
                  additional documents to give final approval. These documents
                  may include financial records, birth certificates, medical
                  certificates, and business or employment-related documents.
                  Providing these additional documents is crucial for the
                  government to assess the applicant's eligibility.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>

          <Row className="mt-4">
            <Col md={3}></Col>
            <Col md={3} className="mb-4">
              <div className="p-4 applyCol">
                <h5 className="cardTitle">4. Visit a Representative Office</h5>
                <p className="cardText">
                  As part of the passport application process, applicants are
                  required to visit a representative office of São Tomé and
                  Príncipe to provide biometric information, such as
                  fingerprints and photographs. This step helps in the
                  identification and verification of the applicant's identity
                  and is an important part of the due diligence process. Local
                  offices can be found <a href="/our-offices">here</a>.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={3} className="mb-4">
              <div className="p-4 applyCol">
                <h5 className="cardTitle">5. Passport Issuance</h5>
                <p className="cardText">
                  After visiting a representative office, finalizing the
                  necessary paperwork, and providing all required biometric
                  data, the applicant will be issued a passport by the São Tomé
                  and Príncipe government. The passport issuance typically takes
                  around 7-10 business days after the final approval is granted.
                  The passport serves as official proof of citizenship and
                  enables the holder to travel internationally.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={3} className="mb-4">
              <div className="p-4 applyCol">
                <h5 className="cardTitle">
                  6. Banking/Visa Issuance & Investing
                </h5>
                <p className="cardText">
                  As new citizens of São Tomé and Príncipe and investors in the
                  STP Development Investment Corp., applicants will gain full
                  banking privileges, including the ability to open a bank
                  account that facilitates global account transfers. Access to a
                  range of financial products and services, including the Haute
                  Black VISA card, will be provided through our partner bank,
                  STP Development Bank.
                </p>
                <Button className="applyButton" href="/invest-now">
                  Apply Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default Apply;
