import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { isMobile } from "react-device-detect";
import Home from "./components/home/home";
import MobileNavBar from "./components/common/mobileNavbar";
import Footer from "./components/common/footer";
import AboutStp from "./components/about/aboutStp";
import AboutCorporation from "./components/about/aboutCorporation";
import Offices from "./components/more/offices";
import Apply from "./components/more/apply";
import Contact from "./components/more/contact";
import CitizenShip from "./components/citizenship/citizenship";
import InvestForm from "./components/invest/investForm";
import SignDocument from "./components/invest/signDocument";
import NavBar from "./components/common/navBar";
import ScrollButton from "./components/common/scroll";
function App() {
  return (
    <div className={isMobile ? "App" : "AppBrowser"}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about-stp" element={<AboutStp />} />
          <Route path="/about-the-corporation" element={<AboutCorporation />} />
          <Route path="/citizenship" element={<CitizenShip />} />
          <Route path="/our-offices" element={<Offices />} />
          <Route path="/how-to-apply" element={<Apply />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/invest-now" element={<InvestForm />} />
          <Route path="/sign-document" element={<SignDocument />} />
        </Routes>
      </BrowserRouter>
      <ScrollButton />
    </div>
  );
}

export default App;
