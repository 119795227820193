export const countries = [
  { label: "Please Select", value: "", code: "", callCode: "" },
  { label: "Afghanistan", value: "AF", code: "004", callCode: "93" },
  { label: "Åland Islands", value: "AX", code: "248", callCode: "35818" },
  { label: "Albania", value: "AL", code: "008", callCode: "355" },
  { label: "Algeria", value: "DZ", code: "012", callCode: "213" },
  { label: "American Samoa", value: "AS", code: "016", callCode: "1684" },
  { label: "Andorra", value: "AD", code: "020", callCode: "376" },
  { label: "Angola", value: "AO", code: "024", callCode: "244" },
  { label: "Anguilla", value: "AI", code: "660", callCode: "1264" },
  { label: "Antarctica", value: "AQ", code: "010", callCode: "672" },
  { label: "Antigua and Barbuda", value: "AG", code: "028", callCode: "1268" },
  { label: "Argentina", value: "AR", code: "032", callCode: "54" },
  { label: "Armenia", value: "AM", code: "051", callCode: "374" },
  { label: "Aruba", value: "AW", code: "533", callCode: "297" },
  { label: "Australia", value: "AU", code: "036", callCode: "61" },
  { label: "Austria", value: "AT", code: "040", callCode: "43" },
  { label: "Azerbaijan", value: "AZ", code: "031", callCode: "994" },
  { label: "Bahamas", value: "BS", code: "044", callCode: "1242" },
  { label: "Bahrain", value: "BH", code: "048", callCode: "973" },
  { label: "Bangladesh", value: "BD", code: "050", callCode: "880" },
  { label: "Barbados", value: "BB", code: "052", callCode: "1246" },
  { label: "Belarus", value: "BY", code: "112", callCode: "375" },
  { label: "Belgium", value: "BE", code: "056", callCode: "32" },
  { label: "Belize", value: "BZ", code: "084", callCode: "501" },
  { label: "Benin", value: "BJ", code: "204", callCode: "229" },
  { label: "Bermuda", value: "BM", code: "060", callCode: "1441" },
  { label: "Bhutan", value: "BT", code: "064", callCode: "975" },
  {
    label: "Bolivia (Plurinational State of)",
    value: "BO",
    code: "068",
    callCode: "591",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
    code: "535",
    callCode: "599",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BA",
    code: "070",
    callCode: "387",
  },
  { label: "Botswana", value: "BW", code: "072", callCode: "267" },
  { label: "Bouvet Island", value: "BV", code: "074", callCode: "55" },
  { label: "Brazil", value: "BR", code: "076", callCode: "55" },
  {
    label: "British Indian Ocean Territory",
    value: "IO",
    code: "086",
    callCode: "246",
  },
  { label: "Brunei Darussalam", value: "BN", code: "096", callCode: "673" },
  { label: "Bulgaria", value: "BG", code: "100", callCode: "359" },
  { label: "Burkina Faso", value: "BF", code: "854", callCode: "226" },
  { label: "Burundi", value: "BI", code: "108", callCode: "257" },
  { label: "Cabo Verde", value: "CV", code: "132", callCode: "238" },
  { label: "Cambodia", value: "KH", code: "116", callCode: "855" },
  { label: "Cameroon", value: "CM", code: "120", callCode: "237" },
  { label: "Canada", value: "CA", code: "124", callCode: "1" },
  { label: "Cayman Islands", value: "KY", code: "136", callCode: "1" },
  {
    label: "Central African Republic",
    value: "CF",
    code: "140",
    callCode: "236",
  },
  { label: "Chad", value: "TD", code: "148", callCode: "235" },
  { label: "Chile", value: "CL", code: "152", callCode: "56" },
  { label: "China", value: "CN", code: "156", callCode: "86" },
  { label: "Christmas Island", value: "CX", code: "162", callCode: "61" },
  {
    label: "Cocos (Keeling) Islands",
    value: "CC",
    code: "166",
    callCode: "61891",
  },
  { label: "Colombia", value: "CO", code: "170", callCode: "57" },
  { label: "Comoros", value: "KM", code: "174", callCode: "269" },
  { label: "Congo", value: "CG", code: "178", callCode: "242" },
  {
    label: "Congo, Democratic Republic of the",
    value: "CD",
    code: "180",
    callCode: "243",
  },
  { label: "Cook Islands", value: "CK", code: "184", callCode: "682" },
  { label: "Costa Rica", value: "CR", code: "188", callCode: "506" },
  { label: "Côte d'Ivoire", value: "CI", code: "384", callCode: "225" },
  { label: "Croatia", value: "HR", code: "191", callCode: "385" },
  { label: "Cuba", value: "CU", code: "192", callCode: "53" },
  { label: "Curaçao", value: "CW", code: "531", callCode: "599" },
  { label: "Cyprus", value: "CY", code: "196", callCode: "357" },
  { label: "Czechia", value: "CZ", code: "203", callCode: "420" },
  { label: "Denmark", value: "DK", code: "208", callCode: "45" },
  { label: "Djibouti", value: "DJ", code: "262", callCode: "253" },
  { label: "Dominica", value: "DM", code: "212", callCode: "1767" },
  { label: "Dominican Republic", value: "DO", code: "214", callCode: "1" },
  { label: "Ecuador", value: "EC", code: "218", callCode: "593" },
  { label: "Egypt", value: "EG", code: "818", callCode: "20" },
  { label: "El Salvador", value: "SV", code: "222", callCode: "503" },
  { label: "Equatorial Guinea", value: "GQ", code: "226", callCode: "240" },
  { label: "Eritrea", value: "ER", code: "232", callCode: "291" },
  { label: "Estonia", value: "EE", code: "233", callCode: "372" },
  { label: "Eswatini", value: "SZ", code: "748", callCode: "268" },
  { label: "Ethiopia", value: "ET", code: "231", callCode: "251" },
  {
    label: "Falkland Islands (Malvinas)",
    value: "FK",
    code: "238",
    callCode: "500",
  },
  { label: "Faroe Islands", value: "FO", code: "234", callCode: "298" },
  { label: "Fiji", value: "FJ", code: "242", callCode: "679" },
  { label: "Finland", value: "FI", code: "246", callCode: "358" },
  { label: "France", value: "FR", code: "250", callCode: "33" },
  { label: "French Guiana", value: "GF", code: "254", callCode: "594" },
  { label: "French Polynesia", value: "PF", code: "258", callCode: "689" },
  {
    label: "French Southern Territories",
    value: "TF",
    code: "260",
    callCode: "262",
  },
  { label: "Gabon", value: "GA", code: "266", callCode: "241" },
  { label: "Gambia", value: "GM", code: "270", callCode: "220" },
  { label: "Georgia", value: "GE", code: "268", callCode: "995" },
  { label: "Germany", value: "DE", code: "276", callCode: "49" },
  { label: "Ghana", value: "GH", code: "288", callCode: "233" },
  { label: "Gibraltar", value: "GI", code: "292", callCode: "350" },
  { label: "Greece", value: "GR", code: "300", callCode: "30" },
  { label: "Greenland", value: "GL", code: "304", callCode: "299" },
  { label: "Grenada", value: "GD", code: "308", callCode: "1473" },
  { label: "Guadeloupe", value: "GP", code: "312", callCode: "590" },
  { label: "Guam", value: "GU", code: "316", callCode: "1671" },
  { label: "Guatemala", value: "GT", code: "320", callCode: "502" },
  { label: "Guernsey", value: "GG", code: "831", callCode: "441481" },
  { label: "Guinea", value: "GN", code: "324", callCode: "224" },
  { label: "Guinea-Bissau", value: "GW", code: "624", callCode: "245" },
  { label: "Guyana", value: "GY", code: "328", callCode: "592" },
  { label: "Haiti", value: "HT", code: "332", callCode: "509" },
  {
    label: "Heard Island and McDonald Islands",
    value: "HM",
    code: "334",
    callCode: "672",
  },
  { label: "Holy See", value: "VA", code: "336", callCode: "379" },
  { label: "Honduras", value: "HN", code: "340", callCode: "504" },
  { label: "Hong Kong", value: "HK", code: "344", callCode: "852" },
  { label: "Hungary", value: "HU", code: "348", callCode: "36" },
  { label: "Iceland", value: "IS", code: "352", callCode: "354" },
  { label: "India", value: "IN", code: "356", callCode: "91" },
  { label: "Indonesia", value: "ID", code: "360", callCode: "62" },
  {
    label: "Iran (Islamic Republic of)",
    value: "IR",
    code: "364",
    callCode: "98",
  },
  { label: "Iraq", value: "IQ", code: "368", callCode: "964" },
  { label: "Ireland", value: "IE", code: "372", callCode: "353" },
  { label: "Isle of Man", value: "IM", code: "833", callCode: "441624" },
  { label: "Israel", value: "IL", code: "376", callCode: "972" },
  { label: "Italy", value: "IT", code: "380", callCode: "39" },
  { label: "Jamaica", value: "JM", code: "388", callCode: "1876" },
  { label: "Japan", value: "JP", code: "392", callCode: "81" },
  { label: "Jersey", value: "JE", code: "832", callCode: "441534" },
  { label: "Jordan", value: "JO", code: "400", callCode: "962" },
  { label: "Kazakhstan", value: "KZ", code: "398", callCode: "7" },
  { label: "Kenya", value: "KE", code: "404", callCode: "254" },
  { label: "Kiribati", value: "KI", code: "296", callCode: "686" },
  {
    label: "Korea (Democratic People's Republic of)",
    value: "KP",
    code: "408",
    callCode: "850",
  },
  { label: "Korea, Republic of", value: "KR", code: "410", callCode: "82" },
  { label: "Kuwait", value: "KW", code: "414", callCode: "965" },
  { label: "Kyrgyzstan", value: "KG", code: "417", callCode: "996" },
  {
    label: "Lao People's Democratic Republic",
    value: "LA",
    code: "418",
    callCode: "856",
  },
  { label: "Latvia", value: "LV", code: "428", callCode: "371" },
  { label: "Lebanon", value: "LB", code: "422", callCode: "961" },
  { label: "Lesotho", value: "LS", code: "426", callCode: "266" },
  { label: "Liberia", value: "LR", code: "430", callCode: "231" },
  { label: "Libya", value: "LY", code: "434", callCode: "218" },
  { label: "Liechtenstein", value: "LI", code: "438", callCode: "423" },
  { label: "Lithuania", value: "LT", code: "440", callCode: "370" },
  { label: "Luxembourg", value: "LU", code: "442", callCode: "352" },
  { label: "Macao", value: "MO", code: "446", callCode: "853" },
  { label: "Madagascar", value: "MG", code: "450", callCode: "261" },
  { label: "Malawi", value: "MW", code: "454", callCode: "265" },
  { label: "Malaysia", value: "MY", code: "458", callCode: "60" },
  { label: "Maldives", value: "MV", code: "462", callCode: "960" },
  { label: "Mali", value: "ML", code: "466", callCode: "223" },
  { label: "Malta", value: "MT", code: "470", callCode: "356" },
  { label: "Marshall Islands", value: "MH", code: "584", callCode: "692" },
  { label: "Martinique", value: "MQ", code: "474", callCode: "596" },
  { label: "Mauritania", value: "MR", code: "478", callCode: "222" },
  { label: "Mauritius", value: "MU", code: "480", callCode: "230" },
  { label: "Mayotte", value: "YT", code: "175", callCode: "262" },
  { label: "Mexico", value: "MX", code: "484", callCode: "52" },
  {
    label: "Micronesia (Federated States of)",
    value: "FM",
    code: "583",
    callCode: "691",
  },
  { label: "Moldova, Republic of", value: "MD", code: "498", callCode: "373" },
  { label: "Monaco", value: "MC", code: "492", callCode: "377" },
  { label: "Mongolia", value: "MN", code: "496", callCode: "976" },
  { label: "Montenegro", value: "ME", code: "499", callCode: "382" },
  { label: "Montserrat", value: "MS", code: "500", callCode: "1664" },
  { label: "Morocco", value: "MA", code: "504", callCode: "212" },
  { label: "Mozambique", value: "MZ", code: "508", callCode: "258" },
  { label: "Myanmar", value: "MM", code: "104", callCode: "95" },
  { label: "Namibia", value: "NA", code: "516", callCode: "264" },
  { label: "Nauru", value: "NR", code: "520", callCode: "674" },
  { label: "Nepal", value: "NP", code: "524", callCode: "977" },
  { label: "Netherlands", value: "NL", code: "528", callCode: "31" },
  { label: "New Caledonia", value: "NC", code: "540", callCode: "687" },
  { label: "New Zealand", value: "NZ", code: "554", callCode: "64" },
  { label: "Nicaragua", value: "NI", code: "558", callCode: "505" },
  { label: "Niger", value: "NE", code: "562", callCode: "227" },
  { label: "Nigeria", value: "NG", code: "566", callCode: "234" },
  { label: "Niue", value: "NU", code: "570", callCode: "683" },
  { label: "Norfolk Island", value: "NF", code: "574", callCode: "672" },
  { label: "North Macedonia", value: "MK", code: "807", callCode: "389" },
  {
    label: "Northern Mariana Islands",
    value: "MP",
    code: "580",
    callCode: "1670",
  },
  { label: "Norway", value: "NO", code: "578", callCode: "47" },
  { label: "Oman", value: "OM", code: "512", callCode: "968" },
  { label: "Pakistan", value: "PK", code: "586", callCode: "92" },
  { label: "Palau", value: "PW", code: "585", callCode: "680" },
  { label: "Palestine, State of", value: "PS", code: "275", callCode: "970" },
  { label: "Panama", value: "PA", code: "591", callCode: "507" },
  { label: "Papua New Guinea", value: "PG", code: "598", callCode: "675" },
  { label: "Paraguay", value: "PY", code: "600", callCode: "595" },
  { label: "Peru", value: "PE", code: "604", callCode: "51" },
  { label: "Philippines", value: "PH", code: "608", callCode: "63" },
  { label: "Pitcairn", value: "PN", code: "612", callCode: "64" },
  { label: "Poland", value: "PL", code: "616", callCode: "48" },
  { label: "Portugal", value: "PT", code: "620", callCode: "351" },
  { label: "Puerto Rico", value: "PR", code: "630", callCode: "1787" },
  { label: "Qatar", value: "QA", code: "634", callCode: "974" },
  { label: "Réunion", value: "RE", code: "638", callCode: "262" },
  { label: "Romania", value: "RO", code: "642", callCode: "40" },
  { label: "Russian Federation", value: "RU", code: "643", callCode: "7" },
  { label: "Rwanda", value: "RW", code: "646", callCode: "250" },
  { label: "Saint Barthélemy", value: "BL", code: "652", callCode: "590" },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
    code: "654",
    callCode: "290",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KN",
    code: "659",
    callCode: "1869",
  },
  { label: "Saint Lucia", value: "LC", code: "662", callCode: "1758" },
  {
    label: "Saint Martin (French part)",
    value: "MF",
    code: "663",
    callCode: "590",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "PM",
    code: "666",
    callCode: "508",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
    code: "670",
    callCode: "1784",
  },
  { label: "Samoa", value: "WS", code: "882", callCode: "685" },
  { label: "San Marino", value: "SM", code: "674", callCode: "378" },
  { label: "Sao Tome and Principe", value: "ST", code: "678", callCode: "239" },
  { label: "Saudi Arabia", value: "SA", code: "682", callCode: "966" },
  { label: "Senegal", value: "SN", code: "686", all: "221" },
  { label: "Serbia", value: "RS", code: "688", callCode: "381" },
  { label: "Seychelles", value: "SC", code: "690", callCode: "248" },
  { label: "Sierra Leone", value: "SL", code: "694", callCode: "232" },
  { label: "Singapore", value: "SG", code: "702", callCode: "65" },
  {
    label: "Sint Maarten (Dutch part)",
    value: "SX",
    code: "534",
    callCode: "1721",
  },
  { label: "Slovakia", value: "SK", code: "703", callCode: "421" },
  { label: "Slovenia", value: "SI", code: "705", callCode: "386" },
  { label: "Solomon Islands", value: "SB", code: "090", callCode: "677" },
  { label: "Somalia", value: "SO", code: "706", callCode: "252" },
  { label: "South Africa", value: "ZA", code: "710", callCode: "27" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
    code: "239",
    callCode: "500",
  },
  { label: "South Sudan", value: "SS", code: "728", callCode: "211" },
  { label: "Spain", value: "ES", code: "724", callCode: "34" },
  { label: "Sri Lanka", value: "LK", code: "144", callCode: "94" },
  { label: "Sudan", value: "SD", code: "729", callCode: "249" },
  { label: "Surilabel", value: "SR", code: "740", callCode: "" },
  { label: "Svalbard and Jan Mayen", value: "SJ", code: "744", callCode: "47" },
  { label: "Sweden", value: "SE", code: "752", callCode: "46" },
  { label: "Switzerland", value: "CH", code: "756", callCode: "41" },
  { label: "Syrian Arab Republic", value: "SY", code: "760", callCode: "963" },
  {
    label: "Taiwan, Province of China",
    value: "TW",
    code: "158",
    callCode: "886",
  },
  { label: "Tajikistan", value: "TJ", code: "762", callCode: "992" },
  {
    label: "Tanzania, United Republic of",
    value: "TZ",
    code: "834",
    callCode: "255",
  },
  { label: "Thailand", value: "TH", code: "764", callCode: "66" },
  { label: "Timor-Leste", value: "TL", code: "626", callCode: "670" },
  { label: "Togo", value: "TG", code: "768", callCode: "228" },
  { label: "Tokelau", value: "TK", code: "772", callCode: "690" },
  { label: "Tonga", value: "TO", code: "776", callCode: "676" },
  { label: "Trinidad and Tobago", value: "TT", code: "780", callCode: "1868" },
  { label: "Tunisia", value: "TN", code: "788", callCode: "216" },
  { label: "Turkey", value: "TR", code: "792", callCode: "90" },
  { label: "Turkmenistan", value: "TM", code: "795", callCode: "993" },
  {
    label: "Turks and Caicos Islands",
    value: "TC",
    code: "796",
    callCode: "1649",
  },
  { label: "Tuvalu", value: "TV", code: "798", callCode: "688" },
  { label: "Uganda", value: "UG", code: "800", callCode: "256" },
  { label: "Ukraine", value: "UA", code: "804", callCode: "380" },
  { label: "United Arab Emirates", value: "AE", code: "784", callCode: "971" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "GB",
    code: "826",
    callCode: "44",
  },
  {
    label: "United States of America",
    value: "US",
    code: "840",
    callCode: "1",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "UM",
    code: "581",
    callCode: "699",
  },

  { label: "Uruguay", value: "UY", code: "858", callCode: "598" },
  { label: "Uzbekistan", value: "UZ", code: "860", callCode: "998" },
  { label: "Vanuatu", value: "VU", code: "548", callCode: "678" },
  {
    label: "Venezuela (Bolivarian Republic of)",
    value: "VE",
    code: "862",
    callCode: "58",
  },

  { label: "Viet Nam", value: "VN", code: "704", callCode: "84" },
  {
    label: "Virgin Islands (British)",
    value: "VG",
    code: "092",
    callCode: "1284",
  },

  {
    label: "Virgin Islands (U.S.)",
    value: "VI",
    code: "850",
    callCode: "1340",
  },
  { label: "Wallis and Futuna", value: "WF", code: "876", callCode: "681" },
  { label: "Western Sahara", value: "EH", code: "732", callCode: "212" },
  { label: "Yemen", value: "YE", code: "887", callCode: "967" },
  { label: "Zambia", value: "ZM", code: "894", callCode: "260" },
  { label: "Zimbabwe", value: "ZW", code: "716", callCode: "263" },
];
