import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./about.css";
import { Row, Col, Container, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutOne from "../../assets/photos/aboutOne.png";
import AboutTwo from "../../assets/photos/aboutTwo.png";
import AboutThree from "../../assets/photos/aboutThree.png";
import AboutFour from "../../assets/photos/aboutFour.png";
import geo from "../../assets/photos/geography.png";
import safety from "../../assets/photos/safety.png";
import world from "../../assets/photos/portugal.png";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";
function AboutStp() {
  useEffect(() => {
    document.title = "About STP - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="aboutMobileContainer ">
          <div className="ColMobileOne">
            <h4>About Us</h4>
            <h1>São Tomé and Príncipe</h1>
          </div>
          <Row>
            <Col md={12} className="aboutMobileCol">
              <Image src={AboutOne} className="aboutMobileOne "></Image>
            </Col>
          </Row>
          <Row className="AboutMobileRow">
            <Col md={12} className="ColMobileOne">
              <Image src={geo} className="logos"></Image>
              <h4>Geography</h4>
              <p>
                STP is a small island nation made up of two main islands, Sao
                Tome and Principe, as well as several smaller islands and
                islets.
              </p>
              <p>
                The islands are located in the Gulf of Guinea, off the western
                coast of Central Africa, and are known for their natural beauty
                and rich culture
              </p>
            </Col>
            <Col md={12} className="aboutMobileCol">
              <Image src={AboutTwo} className="aboutMobileTwo"></Image>
            </Col>
          </Row>

          <Row className="AboutMobileRow">
            <Col md={12} className="ColMobileOne">
              <Image src={safety} className="logos"></Image>
              <h4>Safety Statistics</h4>
              <p>
                Sao Tome and Principe is considered one of the safest countries
                in Africa, with a relatively low crime rate and stable political
                climate.
              </p>
              <p>
                The country has a well-trained police force and a small military
                presence, which helps maintain a sense of security for both
                citizens and visitors.
              </p>
            </Col>
            <Col md={12} className="aboutMobileCol">
              <Image src={AboutThree} className="aboutMobileTwo"></Image>
            </Col>
          </Row>

          <Row className="AboutMobileRow">
            <Col md={12} className="ColMobileOne">
              <Image src={world} className="logos"></Image>
              <h4>History Of Country As A Colony Of Portugal</h4>
              <p>
                Sao Tome and Principe was a Portuguese colony for over 400
                years, from the late 15th century until its independence in
                1975.
              </p>
              <p>
                During this period, the islands became an important center for
                transatlantic trade and was used to grow cash crops like sugar,
                cocoa, and coffee.
              </p>
              <p>
                The legacy of Portuguese colonialism can still be seen in the
                country’s culture, language, and architecture.
              </p>
            </Col>
            <Col md={12} className="aboutMobileCol">
              <Image src={AboutFour} className="aboutMobileTwo"></Image>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="aboutContainer">
          <h4>About Us</h4>
          <h1>São Tomé and Príncipe</h1>
          <Row>
            <Col md={12}>
              <Image src={AboutOne} className="aboutOne mt-4"></Image>
            </Col>
          </Row>

          <Row className="AboutRow">
            <Col md={5} className="ColOne">
              <Image src={geo} className="logos"></Image>
              <h4>Geography</h4>
              <p>
                STP is a small island nation made up of two main islands, Sao
                Tome and Principe, as well as several smaller islands and
                islets.
              </p>
              <p>
                The islands are located in the Gulf of Guinea, off the western
                coast of Central Africa, and are known for their natural beauty
                and rich culture
              </p>
            </Col>
            <Col md={7}>
              <Image src={AboutTwo} className="aboutTwo"></Image>
            </Col>
          </Row>

          <Row className="AboutRow">
            <Col md={7}>
              <Image src={AboutThree} className="aboutTwo"></Image>
            </Col>
            <Col md={5} className="RowColSecond">
              <Image src={safety} className="logos"></Image>
              <h4>Safety Statistics</h4>
              <p>
                Sao Tome and Principe is considered one of the safest countries
                in Africa, with a relatively low crime rate and stable political
                climate.
              </p>
              <p>
                The country has a well-trained police force and a small military
                presence, which helps maintain a sense of security for both
                citizens and visitors.
              </p>
            </Col>
          </Row>

          <Row className="AboutRow">
            <Col md={5} className="ColOne">
              <Image src={world} className="logos"></Image>
              <h4>History Of Country As A Colony Of Portugal</h4>
              <p>
                Sao Tome and Principe was a Portuguese colony for over 400
                years, from the late 15th century until its independence in
                1975.
              </p>
              <p>
                During this period, the islands became an important center for
                transatlantic trade and was used to grow cash crops like sugar,
                cocoa, and coffee.
              </p>
              <p>
                The legacy of Portuguese colonialism can still be seen in the
                country’s culture, language, and architecture.
              </p>
            </Col>
            <Col md={7}>
              <Image src={AboutFour} className="aboutTwo"></Image>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default AboutStp;
