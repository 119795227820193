import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./citizenship.css";
import { Row, Col, Container, Image, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import passport from "../../assets/photos/passport.png";
import banking from "../../assets/photos/banking.png";
import visa from "../../assets/photos/visa.png";
import secondLogo from "../../assets/photos/secondLogo.png";
import unLogo from "../../assets/photos/unEmblum.png";
import africa from "../../assets/photos/africa.png";
import emblum from "../../assets/photos/emblum.png";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";

function CitizenShip() {
  useEffect(() => {
    document.title = "Citizenship - STP Developement";
  });
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <>
          <Container fluid className="citizenMobileContainer">
            <Col style={{ paddingLeft: 10, paddingRight: 10 }}>
              <h1>Citizenship</h1>
              <h1>
                {" "}
                by Investment<span>.</span>
              </h1>
              <p className="mt-3 citizenshipMainContent">
                Key benefits of becoming an investor in the STP Development
                Investment Corporation is the eligibility to apply for a new STP
                passport, open bank accounts and access a range of financial
                products and services, all while your investment is growing
              </p>

              <Row className="secondMobileCitizenRow">
                <Col md={12}>
                  <h2>Passport Access</h2>

                  <Image src={passport} className="cardMobileimage"></Image>
                  <p className="firstColPara">
                    One of the key benefits of becoming an investor in the STP
                    Development Investment Corporation is the eligibility to
                    apply for a new STP passport.
                  </p>
                  <p className="firstColPara">
                    STP passports are highly valued for their fast-track EU visa
                    and residency/work permit access, which can open a range of
                    new opportunities for business, travel, and personal growth.
                  </p>
                  <p className="firstColPara">
                    STP passport holders also enjoy a range of other benefits,
                    including access to a global network of consular services
                    and diplomatic protection.
                  </p>
                </Col>
                <Col md={12}>
                  <h2>Banking Access</h2>
                  <div style={{ display: "flex" }}>
                    <Image src={banking} className="cardMobileimage2"></Image>
                    <Image src={secondLogo} className="logoMobileimage"></Image>
                  </div>
                  <p className="secondColPara">
                    Investors in the STP Development Investment Corporation will
                    have full banking abilities in STP, including the ability to
                    open accounts, make transfers, and access a range of
                    financial products and services through our partner bank STP
                    Development Bank.
                  </p>
                  {/* <Image src={secondLogo} className="logoimage"></Image> */}
                </Col>
                <Col md={12}>
                  <h2>Visa Card Access</h2>

                  <Image src={visa} className="cardMobileimage"></Image>
                  <p className="thirdColPara">
                    The corporation will offer qualified investors a Haute Black
                    Visa card with no limit, which can be used to make purchases
                    and withdraw cash at millions of locations worldwide.
                  </p>
                  <p className="thirdColPara">
                    The Haute Black Visa card is a powerful tool for investors
                    who wish to access their partnerships quickly and securely,
                    without the need for traditional banking channels
                  </p>
                </Col>
              </Row>
            </Col>

            <Row className="citizenSecondMobileRow">
              <h1>Status in The UN</h1>
              <h3 className="mt-4 float-center">
                Sao Tome and Principe is a member of the United Nations, the
                African Union, and the Community of Portuguese Language
                Countries, among other international organizations.
              </h3>
              <h3 className="mt-4">
                The country is recognized as a developing nation, with a GDP per
                capita of around $2,000 (2021 est.) and a Human Development
                Index (HDI) score of 0.625 (2020).
              </h3>
              <div style={{ display: "flex" }} className="mt-4">
                <Col sm={4} xs={4}>
                  <Image src={unLogo} className="mobileEmblum"></Image>
                </Col>
                <Col sm={4} xs={4}>
                  <Image src={africa} className="mobileEmblum2"></Image>
                </Col>
                <Col sm={4} xs={4}>
                  <Image src={emblum} className="mobileEmblum3"></Image>
                </Col>
              </div>
              <div style={{ display: "flex" }} className="mt-4 mobilesNumbers">
                <Col sm={6} xs={6}>
                  <h1>100k</h1>
                  <h3>For Individuals</h3>
                </Col>
                <Col sm={6} xs={6}>
                  <h1>150k</h1>
                  <h3>For Couples</h3>
                </Col>
              </div>
              <div style={{ display: "flex" }} className="mt-4 mobilesNumbers">
                <Col sm={3} xs={3}></Col>
                <Col sm={6} xs={6}>
                  <h5>Up to</h5>
                  <h1>250k</h1>
                  <h3>For Families</h3>
                </Col>
                <Col sm={3} xs={3}></Col>
              </div>
            </Row>
            {/* <Row className="mt-4"></Row> */}
          </Container>
        </>
      ) : (
        <>
          <Container fluid className="citizenContainer">
            <Row>
              <Col md={6}>
                <h1>Citizenship</h1>
                <h1>
                  by Investment<span>.</span>
                </h1>
              </Col>
              <Col md={6}>
                <p className="mt-3 citizenshipMainContent">
                  Key benefits of becoming an investor in the STP Development
                  Investment Corporation is the eligibility to apply for a new
                  STP passport, open bank accounts and access a range of
                  financial products and services, all while your investment is
                  growing
                </p>
              </Col>
            </Row>
            <Row className="secondCitizenRow">
              <Col md={4}>
                <h2>
                  Passport
                  <br />
                  Access
                </h2>

                <Image src={passport} className="cardimage"></Image>
                <p className="firstColPara">
                  One of the key benefits of becoming an investor in the STP
                  Development Investment Corporation is the eligibility to apply
                  for a new STP passport.
                </p>
                <p className="firstColPara">
                  STP passports are highly valued for their fast-track EU visa
                  and residency/work permit access, which can open a range of
                  new opportunities for business, travel, and personal growth.
                </p>
                <p className="firstColPara">
                  STP passport holders also enjoy a range of other benefits,
                  including access to a global network of consular services and
                  diplomatic protection.
                </p>
              </Col>
              <Col md={4}>
                <h2>
                  Banking
                  <br />
                  Access
                </h2>

                <Image src={banking} className="cardimage"></Image>
                <p className="secondColPara">
                  Investors in the STP Development Investment Corporation will
                  have full banking abilities in STP, including the ability to
                  open accounts, make transfers, and access a range of financial
                  products and services through our partner bank STP Development
                  Bank.
                </p>
                <Image src={secondLogo} className="logoimage"></Image>
              </Col>
              <Col md={4}>
                <h2>
                  Visa Card
                  <br />
                  Access
                </h2>

                <Image src={visa} className="cardimage"></Image>
                <p className="thirdColPara">
                  The corporation will offer qualified investors a Haute Black
                  Visa card with no limit, which can be used to make purchases
                  and withdraw cash at millions of locations worldwide.
                </p>
                <p className="thirdColPara">
                  The Haute Black Visa card is a powerful tool for investors who
                  wish to access their partnerships quickly and securely,
                  without the need for traditional banking channels
                </p>
              </Col>
            </Row>
          </Container>
          <Container fluid className="secondContainerCitizen">
            <div className="secondContainerCitizenDiv">
              <h1>Status in The UN</h1>
              <h3 className="mt-4 float-center">
                Sao Tome and Principe is a member of the United Nations, the
                African Union, and the Community of Portuguese Language
                Countries, among other international organizations.
              </h3>
              <h3 className="mt-4">
                The country is recognized as a developing nation, with a GDP per
                capita of around $2,000 (2021 est.) and a Human Development
                Index (HDI) score of 0.625 (2020).
              </h3>
            </div>
            <Row className="thirdRow">
              <Col md={4}>
                <Image src={unLogo} className="emblum"></Image>
              </Col>
              <Col md={4}>
                <Image src={africa} className="emblum2"></Image>
              </Col>
              <Col md={4}>
                <Image src={emblum} className="emblum3"></Image>
              </Col>
            </Row>

            <Container fluid className="thirdCitizenRow">
              <div className="numbersRow">
                {/* <Col md={4}> */}
                <div>
                  <h1>100k</h1>
                  <h3>For Individuals</h3>
                </div>
                {/* </Col>
                <Col md={4}> */}
                <div>
                  <h1>150k</h1>
                  <h3>For Couples</h3>
                </div>
                {/* </Col>
                <Col md={4}> */}
                <div>
                  <h5>Up to</h5>
                  <h1>250k</h1>
                  <h3 className="familyText">For Families</h3>
                </div>
                {/* </Col> */}
              </div>
            </Container>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
}

export default CitizenShip;
