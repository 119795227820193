import React, { useState } from "react";
import "../home/home.css";
import { Row, Image, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/photos/stpLogo.webp";
import { isMobile } from "react-device-detect";

function Footer() {
  return (
    <>
      {isMobile ? (
        <Container fluid className="thirdContainer">
          <div className="MobilefooterRow">
            <Col xs={12} sm={12} className="footerFirstCol">
              <Image src={logo} width={120} />
              <div className="footerGrid">
                <h5>Head Office</h5>
                <div className="mt-3">
                  <h6>2332 Galiano St,</h6>
                  <h6> Coral Gables, FL 33134, USA</h6>
                </div>
              </div>
              <div className="footerGrid">
                <h5>Inquiries</h5>
                <div className="mt-3">
                  <h6>For any inquiries or questions</h6>
                  <h6>please call or email: </h6>
                  <h6>1 (800) 249-5110</h6>
                  <h6>
                    <a className="link" href="mailto: info@stpdevlp.com">
                      info@stpdevlp.com
                    </a>
                  </h6>
                </div>
              </div>
            </Col>
          </div>
          {/* <div className="MobilefooterRow">
            <Col className="footerMobileSecondCol">
              <h5>Inquiries</h5>
              <div className="footerMobileSecondColContent">
                <h6>For any inquiries or questions</h6>
                <h6>please call or email: </h6>
                <h6>1 (800) 249-5110</h6>
                <h6>
                  <a href="mailto: info@stpdevlp.com">info@stpdevlp.com</a>
                </h6>
              </div>
            </Col>
          </div> */}
          <div className="footerMobileCopyRights">
            <h5 className="text-center">
              © 2023 by São Tomé And Príncipe Development Investment Corporation
            </h5>
          </div>
        </Container>
      ) : (
        <Container fluid className="thirdContainer">
          <Row className="thirdContent">
            <hr className="footerHr"></hr>
          </Row>
          <div className="footerRow">
            <Col className="footerFirstCol">
              <Image src={logo} width={120} style={{ marginRight: "47%" }} />
              <div className="footerGrid">
                <h5 className="float-end">Head Office</h5>
                <div className="mt-3">
                  <h6>2332 Galiano St,</h6>
                  <h6> Coral Gables, FL 33134, USA</h6>
                </div>
              </div>
            </Col>
            <Col className="footerSecondCol">
              <h5>Inquiries</h5>
              <div className="footerSecondColContent">
                <h6>For any inquiries or questions</h6>
                <h6>please call or email: </h6>
                <h6>1 (800) 249-5110</h6>
                <h6>
                  <a className="link" href="mailto: info@stpdevlp.com">
                    info@stpdevlp.com
                  </a>
                </h6>
              </div>
            </Col>
          </div>
          <div className="footerCopyRights">
            <h5 className="text-center">
              © 2023 by São Tomé And Príncipe Development Investment Corporation
            </h5>
          </div>
        </Container>
      )}
    </>
  );
}

export default Footer;
