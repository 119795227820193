import {
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Container,
  Offcanvas,
} from "react-bootstrap";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/photos/stpLogo.webp";
import "../home/home.css";
import { Link, useLocation } from "react-router-dom";

function MobileNavBar() {
  console.log("Mobile Loca");
  // const locationre = useLocation();
  // console.log("location", locationre);
  const [show, setShow] = useState(false);
  const [moreShow, setMoreShow] = useState(false);
  const [active, setActive] = useState("default");
  const location = window.location.pathname;

  const showDropdown = (e) => {
    setTimeout(() => {
      setShow(!show);
    }, 1000);
  };
  const hideDropdown = (e) => {
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };
  const showMoreDropdown = (e) => {
    setTimeout(() => {
      setMoreShow(!moreShow);
    }, 1000);
  };
  const hideMoreDropdown = (e) => {
    setTimeout(() => {
      setMoreShow(false);
    }, 1000);
  };
  // const params = useParams();
  console.log("queryParametersqueryParameters", location, active);
  return (
    <>
      <Navbar
        key="sm"
        expand="sm"
        className={`${location}` === "/" ? "homeMobileNav" : "mobileNav"}
      >
        <Container fluid className="NavBarContainer">
          <Navbar.Brand className="brand">
            <Link to={"/"}>
              <Image
                className="mobileLogo mx-auto col-3 float-initial"
                src={logo}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-sm" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-sm"
            aria-labelledby="offcanvasNavbarLabel-expand-sm"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-sm">
                {/* Offcanvas */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                activeKey={location}
                onSelect={(selectedKey) => setActive(selectedKey)}
                className="justify-content-end flex-grow-1 pe-3 navGroup"
              >
                <div className="navItems" id={location}>
                  <Nav.Link
                    // href="/"
                    id="navItem"
                    className={location === "/" ? "yellowItem" : "greyItem"}
                    eventKey="default"
                  >
                    <Link to={"/"}>Home</Link>
                  </Nav.Link>
                  <NavDropdown
                    title="About"
                    id="offcanvasNavbarDropdown-expand-sm"
                    className={`desktopDropDownNav ${
                      (location === "/about-stp" && "yellowItem") ||
                      (location === "/about-the-corporation" && "yellowItem")
                    } `}
                    // show={show}
                    // onMouseEnter={showDropdown}
                    // onMouseLeave={hideDropdown}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 14,
                      paddingRight: 14,
                      backgroundColor: "transparent",
                      color: "rgba(0,0,0,0.9)",
                      fontWeight: "300",
                    }}
                  >
                    <NavDropdown.Item
                      // href="/about-stp"
                      className={`navDropDownItem dropDown1 ${
                        location === "/about-stp" && "yellowItem"
                      } `}
                      eventKey="about-stp"
                    >
                      <Link to={"/about-stp"}>About STP</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // href="/about-the-corporation"
                      className={`navDropDownItem ${
                        location === "/about-the-corporation" && "yellowItem"
                      }`}
                      eventKey="about-the-corporation"
                    >
                      <Link to={"/about-the-corporation"}>
                        About The Corporation
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    // href="/citizenship"
                    className={
                      location === "/citizenship" ? "yellowItem" : "greyItem"
                    }
                    id="navItem"
                    eventKey="citizenship"
                  >
                    <Link to={"/citizenship"}>Citizenship</Link>
                  </Nav.Link>
                  <NavDropdown
                    title="More"
                    id="offcanvasNavbarDropdown-expand-sm"
                    // show={moreShow}
                    className={`desktopDropDownNav  ${
                      (location === "/our-offices" && "yellowItem") ||
                      (location === "/how-to-apply" && "yellowItem") ||
                      (location === "/contact" && "yellowItem")
                    }`}
                    // onMouseEnter={showMoreDropdown}
                    // onMouseLeave={hideMoreDropdown}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      backgroundColor: "transparent",
                      color: "rgba(0,0,0,0.9)",
                      fontWeight: "300",
                    }}
                  >
                    <NavDropdown.Item
                      // href="/our-offices"
                      className={`navDropDownItem dropDown1 ${
                        location === "/our-offices" && "yelloItem"
                      }`}
                      eventKey="our-offices"
                    >
                      <Link to={"/our-offices"}>Our Offices</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // href="/how-to-apply"
                      className={`navDropDownItem dropDown1 ${
                        location === "/how-to-apply" && "yelloItem"
                      }`}
                      eventKey="how-to-apply"
                    >
                      <Link to={"/how-to-apply"}>How To Apply</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // href="/contact"
                      className={`navDropDownItem ${
                        location === "/contact" && "yelloItem"
                      }`}
                      eventKey="contact"
                    >
                      <Link to={"/contact"}>Contact</Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    id="navItem"
                    // className="yellowItem2"
                    className={`yellowItem2
                      ${location === "/invest-now" && "yellowItem"}
                    `}
                    // href="/invest-now"
                    eventKey="invest-now"
                  >
                    <Link to={"/invest-now"}>Invest Now</Link>
                  </Nav.Link>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default MobileNavBar;
