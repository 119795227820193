import {
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Container,
  Offcanvas,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/photos/stpLogo.webp";
import "../home/home.css";
import { useLocation } from "react-router-dom";

function NavBar() {
  const location = useLocation();
  console.log("location", location);
  return (
    <>
      <Navbar
        key="sm"
        expand="sm"
        className="bg-body-tertiary NavBarDesktop"
        style={{ backgroundColor: "transparent" }}
      >
        <Container>
          <Navbar.Brand href="/">
            <Image className="mobileLogo mx-auto col-3" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-sm" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-sm"
            aria-labelledby="offcanvasNavbarLabel-expand-sm"
            placement="end"
          >
            <Offcanvas.Header closeButton className="mobileNavbarOffCanvas">
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-sm"></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="offCanvasBody">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/" className="mobileNavItemResponsive">
                  Home
                </Nav.Link>

                <NavDropdown
                  title="About"
                  id="offcanvasNavbarDropdown-expand-sm"
                  className="mobileNavItemResponsive"
                >
                  <NavDropdown.Item href="/about-stp">
                    About STP
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/about-the-corporation">
                    About The Corporation
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="/citizenship"
                  className="mobileNavItemResponsive"
                >
                  Citizenship
                </Nav.Link>
                <Nav.Link
                  href="/our-offices"
                  className="mobileNavItemResponsive"
                >
                  Our Offices
                </Nav.Link>
                <Nav.Link
                  href="/how-to-apply"
                  className="mobileNavItemResponsive"
                >
                  How To Apply
                </Nav.Link>
                <Nav.Link href="/contact" className="mobileNavItemResponsive">
                  Contact
                </Nav.Link>

                <Nav.Link
                  href="/invest-now"
                  className="mobileNavItemResponsive"
                >
                  Invest Now
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
