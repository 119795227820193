import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./more.css";
import {
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import contact from "../../assets/photos/contact.png";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../../firebase_setup/firebase";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";

function Contact() {
  useEffect(() => {
    document.title = "Contact - STP Developement";
  });
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(true);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const filter =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleClose = () => {
    setErrorPopup(false);
  };
  const handleSuccessClose = () => {
    setSuccessPopup(false);
    window.location.reload(true);
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (!email.trim()) {
      setErrorPopup(true);
      setLoading(false);
    } else if (!filter.test(email)) {
      setErrorPopup(true);
      setLoading(false);
      // setErrorMessage("Please Enter Valid Email");
    } else {
      setLoading(true);
      let contactData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        subject: subject,
        message: message,
        createdAt: new Date(),
        status: 0,
      };

      // const ref = await collection(db, "contactForm");
      try {
        const docRef = await addDoc(collection(db, "contactForm"), contactData);
        setSuccessPopup(true);
      } catch (err) {
        setLoading(false);
        console.log("err", err);
      }
    }
  };
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="contactMobileContainer">
          <h4>Contact Us</h4>
          <h1>For Inquiries or Questions</h1>
          <p>Please use the form or call us at 1 (800) 249-5110</p>

          <Row>
            <Col md={12} sm={12} xs={12}>
              <Form
                className="contactForm"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <p className="label">First Name</p>
                <Form.Control
                  className="formMobileControls"
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <p className="label">Last Name</p>
                <Form.Control
                  className="formMobileControls"
                  onChange={(e) => setLastName(e.target.value)}
                />

                <p className="label">
                  Email<span>*</span>
                </p>

                <Form.Control
                  className="formMobileControls"
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" className="errorMessage">
                  Please enter valid Email
                </Form.Control.Feedback>

                <p className="label">Subject</p>
                <Form.Control
                  className="formMobileControls"
                  onChange={(e) => setSubject(e.target.value)}
                />

                <Row className="contactSecondRow">
                  <Col>
                    <p className="label">Leave us a message...</p>

                    <Form.Control
                      className="formMobileTextArea"
                      as="textarea"
                      rows={6}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  size="lg"
                  className="contactMoreButton float-start"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
          <Modal
            show={errorPopup}
            onHide={() => handleClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title className="center ModalTitle errorTitle">
                Error
                <Image
                  src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='25' height='25' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"
                  style={{ paddingLeft: 5, paddingBottom: 2 }}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="text-center">Please enter valid Email</h6>
              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mt-4"
              >
                <Col>
                  <center>
                    <Button className="okButton" onClick={() => handleClose()}>
                      Ok
                    </Button>
                  </center>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            show={successPopup}
            onHide={() => handleSuccessClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title className="center ModalTitle successTitle">
                Successfully Submitted
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="text-center">
                We received your response, Thankyou!
              </h6>
              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mt-4"
              >
                <Col>
                  <center>
                    <Button
                      className="okButton"
                      onClick={() => handleSuccessClose()}
                    >
                      Ok
                    </Button>
                  </center>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      ) : (
        <Container fluid className="contactContainer">
          <h4>Contact Us</h4>
          <h1>For Inquiries or Questions</h1>
          <p>Please use the form or call us at 1 (800) 249-5110</p>

          <Row>
            <Col md={6}>
              <Form
                className="contactForm"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <Row>
                  <Col>
                    <p className="label">First Name</p>
                    <Form.Control
                      className="formControls formControlContact"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Col>
                  <Col className="secondCol">
                    <p className="label">Last Name</p>
                    <Form.Control
                      className="formControls formControlContact"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="contactSecondRow">
                  <Col>
                    <p className="label">
                      Email<span>*</span>
                    </p>

                    <Form.Control
                      className="formControls formControlContact"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="errorMessage"
                    >
                      Please Enter valid Email
                    </Form.Control.Feedback>
                  </Col>
                  <Col className="secondCol">
                    <p className="label">Subject</p>
                    <Form.Control
                      className="formControls formControlContact"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="contactSecondRow">
                  <Col>
                    <p className="label">Leave us a message...</p>

                    <Form.Control
                      className="formTextArea"
                      as="textarea"
                      rows={7}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  size="lg"
                  className="contactMoreButton float-start"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Col>
            <Col md={6} className="mt-3">
              <Image src={contact} className="contactImage" />
            </Col>
          </Row>

          <Modal
            show={errorPopup}
            onHide={() => handleClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title className="center ModalTitle errorTitle">
                Error
                <Image
                  src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='25' height='25' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"
                  style={{ paddingLeft: 5, paddingBottom: 2 }}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="text-center">Please enter valid Email</h6>
              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mt-4"
              >
                <Col>
                  <center>
                    <Button className="okButton" onClick={() => handleClose()}>
                      Ok
                    </Button>
                  </center>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            show={successPopup}
            onHide={() => handleSuccessClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title className="center ModalTitle successTitle">
                Successfully Submitted
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="text-center">
                We received your response, Thankyou!
              </h6>
              <Row
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mt-4"
              >
                <Col>
                  <center>
                    <Button
                      className="okButton"
                      onClick={() => handleSuccessClose()}
                    >
                      Ok
                    </Button>
                  </center>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default Contact;
