import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./more.css";
import { Row, Col, Container, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import officeOne from "../../assets/photos/officesOne.png";
import sydney from "../../assets/photos/sydney.png";
import Shenzhen from "../../assets/photos/ shenzhen.png";
import beijing from "../../assets/photos/beijing.png";
import bangkok from "../../assets/photos/bangkok.png";
import miami from "../../assets/photos/miami.png";
import Footer from "../common/footer";
import MobileNavBar from "../common/mobileNavbar";
import NavBar from "../common/navBar";
function Offices() {
  useEffect(() => {
    document.title = "Our Offices - STP Developement";
  }, []);
  return (
    <>
      {!isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile ? (
        <Container fluid className="moreMobileContainer">
          <Row>
            <Col md={12} className="moreMobileCol">
              <Image src={officeOne} className="moreMobileOne"></Image>
              <p className="mobileCentered">
                Our Representative Global Offices
              </p>
            </Col>
          </Row>
          <Row className="officeMobileRow">
            <Col md={12} className="mt-3">
              <h4>Sydney, Australia</h4>
              <Image src={sydney} className="sydney mt-4"></Image>
            </Col>
          </Row>
          <Row className="officeMobileRow">
            <Col md={12} className="mt-3">
              <h4>Shenzhen, China</h4>
              <Image src={Shenzhen} className="sydney mt-4"></Image>
            </Col>
          </Row>

          <Row className="officeMobileRow">
            <Col md={12} className="mt-3">
              <h4>Beijing, China</h4>
              <Image src={beijing} className="sydney mt-4"></Image>
            </Col>
          </Row>

          <Row className="officeMobileRow">
            <Col md={12} className="mt-3">
              <h4>Bangkok, Thailand</h4>
              <Image src={bangkok} className="sydney mt-4"></Image>
            </Col>
          </Row>

          <Row className="officeMobileRow">
            <Col md={12} className="mt-3">
              <h4>Miami, U.S.A</h4>
              <Image src={miami} className="sydney mt-4"></Image>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="moreContainer">
          <Row>
            <Col md={12}>
              <Image src={officeOne} className="moreOne mt-4"></Image>
              <p className="centered">Our Representative Global Offices</p>
            </Col>
          </Row>

          <Row className="officeRow">
            <Col md={6}>
              <h4>Sydney, Australia</h4>
              <Image src={sydney} className="sydney mt-4"></Image>
            </Col>
            <Col md={6}>
              <h4>Shenzhen, China</h4>
              <Image src={Shenzhen} className="sydney mt-4"></Image>
            </Col>
          </Row>

          <Row className="officeRow">
            <Col md={6}>
              <h4>Beijing, China</h4>
              <Image src={beijing} className="sydney mt-4"></Image>
            </Col>
            <Col md={6}>
              <h4>Bangkok, Thailand</h4>
              <Image src={bangkok} className="sydney mt-4"></Image>
            </Col>
          </Row>

          <Row className="officeRow">
            <Col md={6}>
              <h4>Miami, U.S.A</h4>
              <Image src={miami} className="sydney mt-4"></Image>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}

export default Offices;
