import React, { useState } from "react";
import home from "../../assets/videos/home.mp4";
import flag from "../../assets/videos/flag.mp4";
import "./home.css";
import { Row, Col, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
function Invest() {
  return (
    <>
      {isMobile ? (
        <Container fluid className="secondMobileTwoContainer ">
          <div className="mobileInvest">
            <video playsInline autoPlay muted loop className="float-center">
              <source src={flag} type="video/mp4" />
            </video>
            <div className="overlayInvest">
              <div className="secondInvestMobileContent">
                <h3>Eligibility</h3>
                <h1>How to Invest</h1>
                <p>
                  The STP Development Investment Corporation is primarily
                  targeting individual and family investors from Asia, with a
                  special emphasis on Chinese nationals who are seeking new
                  opportunities for growth and diversification.
                </p>
              </div>

              <Row className="numberMobileRow">
                <Col md={12} className="float-start">
                  <h1>100k</h1>
                  <p>For Individuals</p>
                  <hr className="mobileInvestHr"></hr>
                </Col>
                <Col md={12}>
                  <h1>150k</h1>
                  <p>For Couples</p>
                  <hr className="mobileInvestHr"></hr>
                </Col>
                <Col md={12}>
                  <p>Up to</p>
                  <h1>250k</h1>
                  <p>For Families</p>
                  <hr className="mobileInvestHr"></hr>
                </Col>
              </Row>
              <div className="investButtons mt-3">
                <Button size="lg" className="investButton" href="/invest-now">
                  Invest Today
                </Button>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container fluid className="secondContainer">
          <div className="ParallaxVideoTwo">
            <video playsInline id="background-video" autoPlay muted loop>
              <source src={flag} type="video/mp4" />
            </video>
            <div className="secondContent">
              <h3>Eligibility</h3>
              <h1>How to Invest</h1>
              <p>
                The STP Development Investment Corporation is primarily
                targeting individual and family investors from Asia, with a
                special emphasis on Chinese nationals who are seeking new
                opportunities for growth and diversification.
              </p>

              <Row>
                <Col md={6} className="float-start">
                  <Row className="numberRow">
                    <Col md={4} className="float-start numberColumns">
                      <h1>100k</h1>
                      <p>For Individuals</p>
                      <hr className="whiteHr"></hr>
                    </Col>
                    <Col md={4} className="numberColumns">
                      <h1>150k</h1>
                      <p>For Couples</p>
                      <hr className="whiteHr"></hr>
                    </Col>
                    <Col md={4} className="uptoNumberColumns">
                      <p>Up to</p>
                      <h1>250k</h1>
                      <p>For Families</p>
                      <hr className="whiteHr"></hr>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}></Col>
              </Row>
              <div className="homeButtons mt-4">
                {/* <Button size="lg" className="investBtn" href="/invest-now">
                  Invest Today
                </Button> */}
                <Link className="link investBtn" to={"/invest-now"}>
                  Invest Today
                </Link>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Invest;
